import {MoodLightCategory} from 'puffco-api-axios-client';
import React from 'react';
import {TextInput, View} from 'react-native';
import {useSelector} from 'react-redux';
import {ulid} from 'ulid';

import {MoodLightPicker, StyledIcon, useSpinner} from '../../components';
import {
  Alerts,
  Constants,
  Screens,
  Strings,
  appColors,
  checkMarkButtonStyle,
  fillStyle,
} from '../../constants';
import {useSaveMoodLight} from '../../lib/api/MoodLight.hooks';
import {
  useAppDispatch,
  useBackPress,
  useHasLed3Api,
  useLantern,
  useWindowHeight,
  useWindowWidth,
} from '../../lib/hooks';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {findMatchingMoodLight} from '../../lib/moodLightFunctions';
import {connectedPeakSelector} from '../../lib/redux/bleSlice';
import {
  LED2Data,
  LED2NoAnimationData,
  LED3Data,
} from '../../lib/redux/led3data';
import {
  getAccountMoodLightSelector,
  getPeakMoodLightSelector,
  lanternMoodLightSelector,
  upsertPeakMoodLight,
} from '../../lib/redux/moodLightSlice';
import {activeProfilesSelector} from '../../lib/redux/profilesSlice';
import styled from '../../lib/styled';
import {
  CustomMoodLight,
  Led3Value,
  MoodLightUI,
  MoodType,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useThrottle} from '../../lib/useThrottle';
import {MoodLightControllerNavigatorScreenProps} from '../../navigation/navigators/MainStackNavigator';
import {Alert} from '../../shims/alert';
import {DraggableBottomSheet as MoodLightEditor} from '../components';
import {ControlPanelComponent} from './components/ControlPanelComponent';

const inputTextMultiplier = 16;

const {
  IS_NATIVE_ANDROID,
  PROFILE_NAME_MAX_LENGTH,
  STOCK_MOOD_COLORS,
  MOOD_LIGHT_WRITING_DELAY: {THROTTLE},
  IS_WEB,
} = Constants;

// Maps moodlightValues/meta to older top level properties
const sliderKeyMap: {[x: string]: string} = {
  tempoFrac: 'tempo',
};

type States = {
  moodLightValues: Led3Value[];
};

type Props = MoodLightControllerNavigatorScreenProps<
  typeof Screens.MoodLightController
>;

const getInitialMoodlight = (hasLed3Api: boolean) => {
  const stockMoodLightId = ulid();

  return {
    category: MoodLightCategory.Custom,
    id: stockMoodLightId,
    modified: new Date().getTime(),
    name: 'NEW MOOD',
    colors: STOCK_MOOD_COLORS.slice(0, 3), // TODO we have a ui.default for colors but user doesn't pick animation until after colors
    type: hasLed3Api ? MoodType.LAVA_LAMP : MoodType.DISCO,
    tempo: 0.5,
    originalMoodLightId: stockMoodLightId,
    led3Meta: {
      userColors: STOCK_MOOD_COLORS.slice(0, 3),
    },
  } as CustomMoodLight;
};

export const MoodLightControllerScreen = ({route, navigation}: Props) => {
  useDisconnectGuard();

  const dispatch = useAppDispatch();
  const {windowHeight: height} = useWindowHeight();
  const {windowWidth: width} = useWindowWidth();
  const {bottom} = useAdaptiveSafeArea();

  const colorPickerContainerDiameter = (width / 2 + height / 2) / 2;
  const nameMaxWidth = width * 0.8;

  const id = route.params?.id ?? '';
  const isFromEditProfile = route.params?.isFromEditProfile ?? false;

  const lanternMoodLightId = useSelector(lanternMoodLightSelector)?.id;
  const getAccountMoodLight = useSelector(getAccountMoodLightSelector);
  const getPeakMoodLight = useSelector(getPeakMoodLightSelector);
  const actives = useSelector(activeProfilesSelector);
  const nameRef = React.useRef<TextInput>(null);
  const peak = useSelector(connectedPeakSelector);

  const hasLed3Api = useHasLed3Api();

  const [moodLight, setMoodLight] = React.useState<CustomMoodLight>(
    getAccountMoodLight(id) ??
      (getPeakMoodLight(id) as CustomMoodLight) ??
      getInitialMoodlight(hasLed3Api),
  );

  // TODO how to handle different Peaks with different capabilities?
  const uiList = (
    hasLed3Api
      ? LED3Data[moodLight.type as keyof typeof LED3Data]
      : moodLight.type === MoodType.NO_ANIMATION
        ? LED2NoAnimationData
        : LED2Data
  ).ui;

  const [{moodLightValues}, updateValues] = React.useReducer(
    (state: States, newState: Partial<States>) => ({
      ...state,
      ...newState,
    }),
    {
      moodLightValues: uiList.map(item => ({
        key: item.key,
        defaultValue: item.default,
        value: item.default,
      })),
    },
  );

  const initialMoodLight = id
    ? getAccountMoodLight(id) ?? (getPeakMoodLight(id) as CustomMoodLight)
    : null;

  const isAccountMoodLight = React.useMemo(
    () => !!getAccountMoodLight(id),
    [getAccountMoodLight, id],
  );

  const isPeakMoodLight = React.useMemo(
    () => !!getPeakMoodLight(id),
    [getPeakMoodLight, id],
  );
  const isNewMoodLight = !isAccountMoodLight && !isPeakMoodLight;
  const [shouldOpen, setShouldOpen] = React.useState<boolean>(false);

  useLantern(moodLight, true);

  const inputWidth = Math.min(
    moodLight?.name ? (moodLight?.name?.length + 1) * inputTextMultiplier : 0,
    nameMaxWidth,
  );

  const typeGuard = (customMoodLight: CustomMoodLight) => {
    return {
      ...customMoodLight,
      tempo: Number(customMoodLight.tempo),
      type: Number(customMoodLight.type),
    } as CustomMoodLight;
  };

  const navigateToAnimation = () => {
    navigation.navigate(Screens.MoodLightAnimation, {
      onAnimationValueChange: updateType,
      initialAnimationValue: moodLight.type,
    });
  };

  // Update mood light when the animation is updated for LED3
  React.useEffect(() => {
    if (moodLight.type) {
      const led3Config = hasLed3Api
        ? LED3Data[moodLight.type as keyof typeof LED3Data]
        : LED2Data;
      const uiListTemp = led3Config?.ui || [];

      const getMoodLightValuesForUI = (item: MoodLightUI) => {
        if (hasLed3Api) {
          const {led3Meta} = moodLight;
          if (led3Meta && (led3Meta as any)?.[item.key]) {
            const value = (led3Meta as any)?.[item.key];
            return value;
          } else {
            return item.default;
          }
        } else {
          return (moodLight as any)?.[sliderKeyMap[item.key]] ?? item.default;
        }
      };

      updateValues({
        moodLightValues: uiListTemp.map(item => ({
          key: item.key,
          defaultValue: item.default,
          value: getMoodLightValuesForUI(item),
        })),
      });
    }
  }, [moodLight.type, hasLed3Api]);

  React.useEffect(() => {
    return () => {
      navigation.setParams({id: ''});
    };
  }, []);

  const writeUpdatedPeakMoodLights = React.useCallback(
    (updatedMoodLight: CustomMoodLight) => {
      const activesWithUpdatedMoodLight = actives.filter(
        active =>
          isTHeatProfileMoodLight(active) &&
          active.moodLightId === updatedMoodLight.id,
      );
      peak?.writeHeatProfiles(
        activesWithUpdatedMoodLight,
        activesWithUpdatedMoodLight.map(() => updatedMoodLight),
      );
      lanternMoodLightId === updatedMoodLight.id &&
        !isFromEditProfile &&
        peak?.writeLanternMoodLight(updatedMoodLight);
      dispatch(upsertPeakMoodLight(updatedMoodLight));
    },
    [peak, actives, isFromEditProfile, lanternMoodLightId],
  );

  const [{loading: isSaving}, save] = useSaveMoodLight({
    onSuccess: React.useCallback(() => navigation.goBack(), []),
    onError: React.useCallback(
      (error: Error) =>
        Alert.alert(
          isNewMoodLight
            ? Alerts.MOOD_LIGHT_CREATE_ERROR_TITLE
            : Alerts.MOOD_LIGHT_SAVE_ERROR_TITLE,
          error.message,
        ),
      [isNewMoodLight],
    ),
  });

  const saveCustomMoodLight = React.useCallback(() => {
    const updatedMoodLight = {
      ...moodLight,
      modified: new Date().getTime(),
      ...(moodLight.type === MoodType.NO_ANIMATION && {tempo: 0}),
    };
    if (
      moodLight.id !== moodLight.originalMoodLightId ||
      (!isAccountMoodLight && isPeakMoodLight)
    ) {
      updatedMoodLight.id = ulid();
      updatedMoodLight.originalMoodLightId = updatedMoodLight.id;
    }
    if (moodLight.id === moodLight.originalMoodLightId && isPeakMoodLight) {
      writeUpdatedPeakMoodLights(updatedMoodLight);
    }
    setMoodLight(updatedMoodLight);
    save(updatedMoodLight);
  }, [
    isAccountMoodLight,
    isPeakMoodLight,
    moodLight,
    save,
    writeUpdatedPeakMoodLights,
  ]);

  useSpinner({
    color: appColors.white,
    isVisible: isSaving,
    text: Strings.SAVING,
  });

  React.useEffect(() => {
    if (
      moodLight.id !== moodLight.originalMoodLightId ||
      (!isAccountMoodLight && isPeakMoodLight)
    ) {
      Alert.alert(
        Alerts.MOOD_LIGHT_DOWNLOADED_SAVE_TITLE,
        Alerts.MOOD_LIGHT_DOWNLOADED_SAVE_MESSAGE,
        [
          {
            text: 'Cancel',
            onPress: () => navigation.goBack(),
          },
          {
            text: 'Continue',
          },
        ],
      );
    }
  }, []);

  React.useEffect(() => {
    if (!initialMoodLight) {
      navigation.setOptions({
        headerRight: () => (
          <StyledIcon
            {...checkMarkButtonStyle}
            iconStyle={{tintColor: appColors.white}}
            {...(!isSaving && {onPress: saveCustomMoodLight})}
          />
        ),
      });
      return;
    } else {
      const matchingMoodLight = findMatchingMoodLight(moodLight, [
        initialMoodLight,
      ]);

      // show checkmark if mood light does not found
      if (!matchingMoodLight) {
        navigation.setOptions({
          headerRight: () => (
            <StyledIcon
              {...checkMarkButtonStyle}
              iconStyle={{tintColor: appColors.white}}
              {...(!isSaving && {onPress: saveCustomMoodLight})}
            />
          ),
        });
      } else if (matchingMoodLight) {
        // hide checkmark if same mood light found
        navigation.setOptions({
          headerRight: () => undefined,
        });
      }
    }
  }, [initialMoodLight, isSaving, moodLight, saveCustomMoodLight, navigation]);

  const onBackPress = React.useCallback(() => {
    navigation.goBack();

    return true;
  }, [navigation]);

  useBackPress(onBackPress);

  // TODO get rid of separate updateColor and updateTempo functions
  // since LED API 3 can't update them separately
  const updateColor = React.useCallback(
    (moodLight: CustomMoodLight) => {
      peak?.writeLanternMoodLight(typeGuard(moodLight));
    },
    [peak],
  );

  const throttledUpdateColor = useThrottle(updateColor, THROTTLE);

  const updateType = (moodType: MoodType) => {
    setMoodLight(prevMoodLight =>
      typeGuard({...prevMoodLight, type: moodType}),
    );
  };

  React.useEffect(() => {
    // map moodLightValues to historical/legacy top level properties
    const moodLight3 = moodLightValues.reduce(
      (acc: {[x: string]: string[] | string | number | undefined}, cur) => {
        if (sliderKeyMap[cur.key]) acc[sliderKeyMap[cur.key]] = cur.value;
        return acc;
      },
      {},
    );

    // map moodLightValues to meta object
    const meta: Record<string, any> = {};
    moodLightValues.forEach(value => {
      meta[value.key] = value.value;
    });

    setMoodLight(prevMoodLight => {
      return {
        ...prevMoodLight,
        ...moodLight3,
        led3Meta: {
          ...prevMoodLight.led3Meta,
          ...meta,
          userColors: prevMoodLight.colors,
        },
      };
    });
  }, [moodLightValues]);

  React.useEffect(() => {
    // Update userColors in led3Meta when they change
    setMoodLight(prevMoodLight => {
      return {
        ...prevMoodLight,
        led3Meta: {
          ...prevMoodLight.led3Meta,
          userColors: moodLight.colors,
        },
      };
    });
  }, [moodLight.colors, moodLight.type]);

  React.useEffect(() => {
    updateColor(typeGuard(moodLight));
  }, [moodLight]);

  const onMoodlightNameInputChange = (text: string) => {
    setMoodLight({
      ...moodLight,
      name: text?.toUpperCase() || '',
    });
  };

  const onMoodlightNameInputBlur = () => {
    if (shouldOpen) {
      nameRef?.current?.focus();
      setShouldOpen(false);
    }
    setMoodLight({
      ...moodLight,
      name: moodLight?.name?.trim() || '',
    });
  };

  const onMoodLightColorPickerValueChange = (colors: string[]) => {
    throttledUpdateColor({...moodLight, colors});
  };

  const onMoodLightColorPickerValueChangeRelease = (colors: string[]) => {
    setMoodLight({
      ...moodLight,
      colors,
    });
  };

  return (
    <ScreenContainer
      style={{backgroundColor: appColors.black, paddingBottom: bottom}}>
      <MoodLightEditor
        header={Strings.CONTROL_PANEL}
        bottomContainer={
          <ControlPanelComponent
            uiList={uiList}
            moodLightValues={moodLightValues}
            setMoodLightValues={v => updateValues({moodLightValues: v})}
            animation={moodLight.type}
            navigateTo={navigateToAnimation}
          />
        }
        bottomSheetStyle={{flex: 1, width: '100%'}}>
        <UpperContainer>
          <TitleContainer>
            <TextInput
              allowFontScaling={false}
              autoCapitalize="characters"
              ref={nameRef}
              value={moodLight?.name || ''}
              onChangeText={onMoodlightNameInputChange}
              keyboardType={IS_NATIVE_ANDROID ? 'visible-password' : 'default'}
              maxLength={PROFILE_NAME_MAX_LENGTH}
              onBlur={onMoodlightNameInputBlur}
              style={{
                fontSize: 24,
                color: appColors.white,
                fontFamily: 'Roboto-Bold',
                fontWeight: '400',
                lineHeight: 26,
                textTransform: 'uppercase',
                textAlign: 'center',
                width: inputWidth,
                maxWidth: '80%',
                marginRight: 6,
              }}
            />

            <StyledIcon
              style={{width: 11, top: IS_WEB ? 0 : -2}}
              iconStyle={{tintColor: appColors.white}}
              size={16}
              name={'pencilOutlined'}
              onPress={() => {
                nameRef.current?.focus();
                IS_WEB && setShouldOpen(true);
              }}
            />
          </TitleContainer>
        </UpperContainer>

        <MoodLightPickerContainer>
          <MoodLightPicker
            diameter={colorPickerContainerDiameter}
            onValueChange={onMoodLightColorPickerValueChange}
            onValueChangeRelease={onMoodLightColorPickerValueChangeRelease}
            colors={moodLight.colors}
          />
        </MoodLightPickerContainer>
      </MoodLightEditor>
    </ScreenContainer>
  );
};

const ScreenContainer = styled(View)({
  ...fillStyle,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const UpperContainer = styled(View)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  flex: 0.6,
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 40,
  padding: 10,
});

const MoodLightPickerContainer = styled(View)({
  display: 'flex',
  flex: 1.5,
  alignItems: 'center',
  justifyContent: 'center',
});
