const heatProfileColor = ['#0000FF', '#69FF00', '#F80B00', '#FFFFFF'];

const baseColors = {
  // this is rainbow sorted
  red: '#FF0000',
  progressRed: '#ff3c30',
  progressYellow: '#ffcb02',
  progressGreen: '#16a909',
  green: '#00FF00',
  batteryGreen: '#00ac00',
  switchGreen: '#2bd056',
  darkGreen: '#439630',
  connectedGreen: '#4CD964',
  readyGreen: '#16A908',
  slideSwitchGreen: '#30D158',
  azureBlue: '#0084DB',
  medianPurple: '#AD00FF',
  darkPurple: '#210f2f',
  iOSBlue: '#007AFF',
  iOSGray: '#E3E3E6',
  pureBlue: '#0000FF',
  // colorless gradients
  white: '#FFFFFF',
  white10: '#FFFFFF1a',
  white15: '#FFFFFF15',
  white20: '#ffffff33',
  white30: '#FFFFFF4D',
  white50: '#FFFFFF80',
  opalWhite: '#F4F4F4',
  lightSmoke: '#dddddd',
  mediumGray50: '#D8D8D880',
  veryLightGray: '#F9F9F9',
  lightestGrey: '#E3E3E3',
  lightGray: '#CCCCCC',
  gray: '#888888',
  gray67: '#ABABAB',
  mediumGray: '#757575',
  mediumGray79: '#79797933',
  mediumDarkGray: '#454545',
  progressBarGray: '#474747',
  ash: '#3B3B3B',
  gold: '#F1E7D6',
  goldMediumGray: '#929280',
  transluscentGray: 'rgba(34, 34, 34, 0.6)',
  clear: 'rgba(0,0,0,0.0)',
  transluscentLightGray: 'rgba(227,227,227,0.2)',
  translucentDarkGray: 'rgba(255,255,255,0.10)',
  darkGray: '#262626',
  darkGreyMedium: '#323232',
  darkGreyMedium33: '#333333',
  bottomSheetGray: '#2B2B2B',
  // background: '#242424',
  background: '#141414',
  coal: '#1A1A1A',
  nearBlack: '#111111',
  nearWhite: '#F7F7F7',
  black: '#000000',
  black10: '#0000001A',
  black20: '#00000033',
  black30: '#0000004D',
  black50: '#00000080',
  greySettings10: '#F8F8F81A',
  greySettings20: '#F8F8F833',
  invisible: '#FFFFFF00',
  transparent: 'rgba(0,0,0,0.7)',
  transparentLight: 'rgba(0,0,0,0.1)',
  transparentLighter: 'rgba(0,0,0,0.05)',
  dark: '#1e1e1e',
  lightMediumGray: '#D8D8D8',
  lightMediumGray30: '#D8D8D84D',
  bone: '#E8E8E8',
  gray19: '#191919',
  textOnSurface: '#B5B5B5',
  textOnSurfaceLight: '#6E6E73',
};
const appBaseColors = {
  errorColor: baseColors.red,
  baseText: baseColors.white,
  baseBackground: baseColors.nearBlack,
  baseBackgroundAccent: baseColors.gray,
};
const componentStateColors = {
  inActiveButtonSlider: '#78788052',
  bottomNavText: baseColors.mediumGray,
  buttonBorder: baseColors.black,
  buttonActiveBackground: baseColors.black,
  buttonActiveText: baseColors.white,
  buttonInactiveBackground: baseColors.black,
  buttonInactiveText: baseColors.gray,
  drawerFade: baseColors.transparent,
  drawerBackground: baseColors.black,
  iconInactive: baseColors.black,
  iconInactiveBackground: baseColors.black,
  iconActiveBackground: baseColors.white,
  iconDisabled: baseColors.gray,
  iconDisabledBackground: baseColors.coal,
  fieldDivider: baseColors.black20,
  iconColor: baseColors.white,
  heatProfileBackground: baseColors.darkGray,
  headerBackground: baseColors.dark,
  modalBackground: 'rgba(12, 12, 12, 0.8)',
  modalSectionBackground: 'rgba(37, 37, 37, 0.6)',
  sectionHeaderText: baseColors.white,
  sectionItemBorder: '#ADADAD',
  signInFieldText: baseColors.black,
  signInFieldTextPlaceholder: baseColors.gray,
  textColor: baseColors.white,
  animationSettingsContainer: '#191919',
};
const alertColors = {
  alertBorder: baseColors.iOSGray,
  alertButtonText: baseColors.iOSBlue,
  alertDisabledBackground: baseColors.black50,
  alertTextInputBackground: baseColors.white,
  alertTextInputPlaceholder: baseColors.iOSGray,
  alertSelectedButton: baseColors.iOSGray,
  alertWindowBackground: baseColors.nearWhite,
};

const peakColors = {
  peakWhite: '#EAEAEA',
};

export const appColors = {
  ...baseColors,
  ...appBaseColors,
  ...componentStateColors,
  ...alertColors,
  ...peakColors,
  defaultColor: baseColors.white,
  heatProfileColor,
};
