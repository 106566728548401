import {AgreementType} from 'puffco-api-axios-client';
import React from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useDispatch, useSelector} from 'react-redux';

import {Analytics} from '../../analytics/Analytics';
import {
  CheckBoxContent,
  StyledButton,
  StyledCheckBox,
  StyledIcon,
} from '../../components';
import {Messages, Screens, Strings, appColors} from '../../constants';
import {agreementApi, agreementTrackingApi} from '../../lib/api/apis';
import {useBackPress} from '../../lib/hooks';
import {useAsyncFn} from '../../lib/hooks/useAsyncFn';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../../lib/redux/appSettingsSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {useSafeArea} from '../../lib/useSafeArea';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {closeApp} from '../../src/util/closeApp';
import {LogoHeader, LogoTitle} from '../components';

interface ScreenProps extends RootStackScreenProps<typeof Screens.LegalIntro> {}

export const LegalIntroScreen: React.FC<ScreenProps> = ({navigation}) => {
  const {top, bottom} = useSafeArea();
  const [accepted, setAccepted] = React.useState(false);

  const user = useSelector(userSelector);
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const dispatch = useDispatch();

  useBackPress(() => true);

  // Start loading agreements as soon as user opens the screen
  const promise = React.useMemo(async () => {
    const [pp, tc] = await Promise.all([
      agreementApi
        .getLatestAgreement({type: AgreementType.Pp})
        .then(r => r.data),
      agreementApi
        .getLatestAgreement({type: AgreementType.Tc})
        .then(r => r.data),
    ]);

    return {
      [AgreementType.Pp]: pp,
      [AgreementType.Tc]: tc,
    };
  }, []);

  const [{loading}, onAccept] = useAsyncFn(
    async (userId?: number): Promise<void> => {
      const agreement = await promise;

      const generatedUserId = agreeAgreement?.generatedUserId;

      if (!generatedUserId) throw new Error('No user id found.');

      await Promise.all(
        [AgreementType.Pp, AgreementType.Tc].map(type =>
          agreementTrackingApi
            .addNewAgreementTracking({
              agreementTrackingCreateDto: {
                type,
                version: agreement[type].version,
                generatedUserId,
                userId,
              },
            })
            .then(r => r.data),
        ),
      );

      dispatch(
        updateAppSettings({
          agreeAgreement: {...agreeAgreement, PP: true, TC: true},
        }),
      );

      Analytics.shared().setAgreeConsent(true);

      navigation.navigate(Screens.Carousel);
    },
    [promise, agreeAgreement, navigation],
  );

  return (
    <View style={{...styles.container, paddingTop: top}}>
      <ScrollView contentContainerStyle={styles.scroller}>
        <LogoHeader>
          <LogoTitle>{Messages.LEGAL_INTRO_TITLE}</LogoTitle>
        </LogoHeader>

        <Text style={styles.message}>{Messages.LEGAL_INTRO_MESSAGE}</Text>

        <View>
          <LinkTouchable
            style={{borderBottomWidth: 1}}
            onPress={() => navigation.navigate(Screens.TermsConditions, {})}>
            <Text style={styles.linkContent}>{Messages.TOS_SECTION_TITLE}</Text>

            <StyledIcon
              iconStyle={styles.linkIcon}
              name="chevronRight"
              size={14}
            />
          </LinkTouchable>

          <LinkTouchable
            onPress={() => navigation.navigate(Screens.PrivacyPolicy, {})}>
            <Text style={styles.linkContent}>
              {Messages.PRIVACY_SECTION_TITLE}
            </Text>

            <StyledIcon
              iconStyle={styles.linkIcon}
              name="chevronRight"
              size={14}
            />
          </LinkTouchable>
        </View>

        <StyledCheckBox
          checked={accepted}
          onChange={accepted => {
            if (loading) return;
            return setAccepted(accepted);
          }}
          style={{alignItems: 'flex-start'}}
          checkboxColor={appColors.coal}>
          <CheckBoxContent style={styles.checkbox}>
            {Messages.LEGAL_INTRO_AGREEMENT}
          </CheckBoxContent>
        </StyledCheckBox>
      </ScrollView>

      <View style={{...styles.buttons, paddingBottom: 24 + bottom}}>
        <PrimaryButton
          title={!loading ? Strings.ACCEPT : undefined}
          disabled={!accepted || loading}
          onPress={() => onAccept(user?.id).catch(() => void 0)}
          textStyle={{color: appColors.white}}>
          {loading && <ActivityIndicator size={24} color={appColors.white} />}
        </PrimaryButton>

        <SecondaryButton
          title={Strings.DECLINE_AND_CLOSE_APP}
          disabled={loading}
          onPress={() => closeApp()}
          textStyle={{color: appColors.black}}
        />
      </View>
    </View>
  );
};

const LinkTouchable = styled(TouchableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 48,
  borderColor: appColors.lightestGrey,
});

const PrimaryButton = styled(StyledButton)({
  backgroundColor: appColors.black,
  width: '100%',
});

const SecondaryButton = styled(StyledButton)({
  backgroundColor: appColors.white,
  borderWidth: 1,
  width: '100%',
  borderColor: appColors.lightMediumGray,
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: appColors.white,
    flex: 1,
    flexBasis: 0,
  },
  scroller: {
    display: 'flex',
    gap: 32,
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  message: {
    fontFamily: 'Roboto-Regular',
    fontWeight: '400',
    color: appColors.black,
    lineHeight: 26,
    fontSize: 16,
  },
  linkContent: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: appColors.black,
  },
  linkIcon: {
    tintColor: appColors.black,
  },
  checkbox: {
    fontSize: 16,
  },
  buttons: {
    display: 'flex',
    gap: 8,
    paddingHorizontal: 24,
    backgroundColor: appColors.white,
  },
});
