import React from 'react';
import {Dimensions, View} from 'react-native';

import {
  AppText,
  ImgBackground,
  PeakImageWithStaticChamber,
  StatusDisplay,
  StyledButton,
} from '../../../components';
import {
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../../constants';
import {useAdaptiveSafeArea} from '../../../lib/hooks/useAdaptiveSafeArea';
import styled from '../../../lib/styled';
import {HomeEmulatedDrawerStackScreenProps} from '../../../navigation/navigators/HomeDrawerNavigator';
import {SafeAreaView} from '../../../shims/SafeAreaView';
import {defaultTheme} from '../../../themes';
import {BodyContainer, BodyText, HeaderText} from '../components';
import {useUpdateFirmware} from './useUpdateFirmware';

interface Props
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.FirmwareUpdating> {}

export const FirmwareUpdatingScreen = ({navigation}: Props) => {
  const windowWidth = Dimensions.get('window').width;

  const [error, setError] = React.useState<Error>();
  const {loading, progress, start} = useUpdateFirmware();

  useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        error ? (
          <CancelButton
            onPress={() =>
              navigation.navigate(Navigators.HomeTabNavigator, {
                screen: Screens.Home,
              })
            }>
            {Strings.CANCEL}
          </CancelButton>
        ) : null,
    });
  }, [navigation]);

  const update = React.useCallback(() => {
    setError(undefined);
    return start().catch(setError);
  }, [start]);

  React.useEffect(() => {
    update();
  }, [update]);

  const getTitleAndBody = () => {
    if (error) return {title: error.name, body: error.message};

    if (loading) return Messages.OTA.UPDATING;

    if (progress.data === 'done') return Messages.OTA.UPDATED;

    return {
      title: 'Reconnect to device',
      body: 'Reconnect to the device to see the changes',
    };
  };

  const getLoadingHaloColor = () => {
    if (progress.data === 'done') return appColors.connectedGreen;

    return '#D8D8D8';
  };

  const getStatusBarStatus = () => {
    switch (progress.data) {
      case 'not_started':
        return '';
      case 'downloading_firmware':
        return 'DOWNLOADING';
      case 'checking_device':
        return 'CHECKING DEVICE';
      case 'updating':
        return 'UPDATING';
      case 'rebooting_to_apploader':
        return 'REBOOTING';
      case 'reconnect':
        return 'RECONNECTING TO DEVICE';
      case 'done':
        return 'COMPLETE';
      default:
        return 'CONNECTING';
    }
  };

  const {title, body} = getTitleAndBody();

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1}}>
        <BodyContainer>
          <HeaderText>{title}</HeaderText>

          <BodyText style={{color: appColors.white50}}>{body}</BodyText>
        </BodyContainer>

        <DownloadContainer>
          <PeakImageWithStaticChamber
            colorSource={appColors.white}
            style={{
              flexDirection: 'column',
              width: '100%',
              height: '80%',
              marginTop: '20%',
            }}
            peakImageContainerProps={{
              style: {
                width: '100%',
                height: '110%',
              },
            }}
            haloProps={{
              percentage: progress.value,
              color: getLoadingHaloColor(),
              style: {
                marginTop: -windowWidth * 0.2,
                height: '100%',
                width: '100%',
                padding: 15,
              },
            }}
            darkScreen={true}
          />
        </DownloadContainer>

        <LowerContainer>
          <StatusDisplay
            status={getStatusBarStatus()}
            error={error?.message}
            theme={defaultTheme}
            {...((
              [
                'updating',
                'downloading_firmware',
              ] as (typeof progress)['data'][]
            ).includes(progress.data) && {
              percentage: Math.round(progress.value * 100),
            })}
          />

          {progress.data === 'done' && (
            <StyledButton
              style={{marginBottom: 25, marginTop: 25}}
              disabled={loading}
              onPress={() =>
                navigation.navigate(Navigators.HomeTabNavigator, {
                  screen: Screens.Home,
                })
              }
              title={Strings.DONE}
            />
          )}

          {error && (
            <StyledButton
              style={{marginBottom: 25, marginTop: 25}}
              onPress={() => update()}
              title={Strings.TRY_AGAIN}
            />
          )}
        </LowerContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const DownloadContainer = styled(View)({
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 3,
});

const LowerContainer = styled(View)({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flex: 1,
  paddingVertical: 10,
});

const CancelButton = styled(AppText)({
  color: appColors.gray,
  fontSize: 14,
  textAlignVertical: 'center',
  marginRight: 16,
});
