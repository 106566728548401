import {
  DrawerContentComponentProps,
  useDrawerStatus,
} from '@react-navigation/drawer';
import {DrawerActions} from '@react-navigation/native';
import React from 'react';
import {Helmet} from 'react-helmet';
import {StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {Alert, Interstitial, Modal, StyledIcon} from '../../components';
import {
  Alerts,
  Constants,
  Navigators,
  Screens,
  Strings,
  appColors,
  interpolate,
} from '../../constants';
import {InterstitialManager} from '../../lib/InterstitialManager';
import {useInterstitial} from '../../lib/hooks';
import {useReferralConfig} from '../../lib/hooks/useReferralConfig';
import {useReferrals} from '../../lib/hooks/useReferrals';
import {navigateToWebsite} from '../../lib/navigateToWebsite';
import {
  bleConnectionStatusSelector,
  isOtaAvailableSelector,
} from '../../lib/redux/bleSlice';
import styled from '../../lib/styled';
import {Store} from '../../lib/types';
import {useTheme} from '../../lib/useTheme';
import {Badge} from '../../shims/Badge';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {flourishTheme} from '../../themes';
import {toAdvancedMetrics, toHome} from '../navigators/util';
import {DrawerItem} from './DrawerItem';

export enum DrawerStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export const DrawerContent: React.FC<DrawerContentComponentProps> = ({
  navigation,
}) => {
  const user = useSelector((store: Store) => store.user);
  const drawerStatus = useDrawerStatus();

  const isOtaAvailable = useSelector(isOtaAvailableSelector);
  const connected = useSelector(bleConnectionStatusSelector);

  const hasFWUpdate = connected && isOtaAvailable;

  const theme = useTheme();
  const isFlourish = theme === flourishTheme;

  const {value: interstitial} = useAsync(
    () => InterstitialManager.interstitialPromise,
    [],
  );
  const {unused} = useReferrals();

  const {data: config} = useReferralConfig();

  const amount = config?.amount ?? '';

  useInterstitial();

  return (
    <SafeAreaView style={styles.layout}>
      {drawerStatus === DrawerStatus.OPEN && Constants.IS_IOS_WEB && (
        <Helmet>
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="default"
          />
        </Helmet>
      )}

      <LeftIcon
        name="menuTriple"
        onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
        {...(isFlourish && {color: flourishTheme.navMenuIconColor})}
        size={26}
      />

      <DrawerItem
        onPress={() =>
          navigation.navigate(Navigators.HomeTabNavigator, {
            screen: Screens.Home,
          })
        }
        iconName="home"
        title="Home"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.DevicesList)}
        iconName="bluetoothBordered"
        title="Devices"
      />

      <DrawerItem
        onPress={() => navigateToWebsite(Constants.SHOP_URL_PATH)}
        iconName="shopBordered"
        title="Shop"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Screens.Preferences)}
        iconName="settingsBordered"
        title="Settings"
        badge={
          !!hasFWUpdate && (
            <Badge>
              <Text style={styles.badgeText}>{1}</Text>
            </Badge>
          )
        }
      />

      <DrawerItem
        onPress={() => {
          if (user) return navigation.navigate(Screens.AdvancedMetrics);

          Alert.alert(Alerts.ACCESS_DENIED, Alerts.FEATURE_ACCESS_MESSAGE_2, [
            {
              text: Strings.SIGN_IN,
              onPress: () => {
                navigation.navigate(Screens.Login, {
                  redirect: toAdvancedMetrics.encode(),
                });
              },
            },
            {
              text: Strings.CREATE_ACCOUNT,
              onPress: () => {
                navigation.navigate(Screens.Register, {
                  redirect: toAdvancedMetrics.encode(),
                });
              },
            },
            {
              text: Strings.CANCEL,
              style: 'cancel',
            },
          ]);
        }}
        iconName="stat"
        title={Strings.ADVANCED_METRICS}
      />

      <DrawerItem
        onPress={() => {
          if (user) return navigation.navigate(Navigators.Account);

          navigation.navigate(Screens.Login, {redirect: toHome});

          navigation.dispatch(DrawerActions.closeDrawer());
        }}
        iconName="userBordered"
        title="Account"
      />

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.DeviceEducation)}
        iconName="questionMark"
        title={Strings.DEVICE_EDUCATION.SUPPORT_SCREEN.TITLE}
      />

      {!!interstitial && (
        <DrawerItem
          onPress={() => {
            navigation.dispatch(DrawerActions.closeDrawer());
            Modal.display({element: <Interstitial {...{interstitial}} />});
          }}
          iconName="envelope"
          title="Notifications"
        />
      )}

      <DrawerItem
        onPress={() => navigation.navigate(Navigators.ReferralProgram)}
        iconName="addUser"
        title={Strings.REFERRAL_PROGRAM.REFER_A_FRIEND}
        badge={
          <Badge>
            <Text
              numberOfLines={1}
              style={[
                styles.badgeText,
                unused.length === 0 && {
                  paddingHorizontal: 10,
                  paddingVertical: 6,
                },
              ]}>
              {unused.length > 0
                ? unused.length
                : interpolate(Strings.REFERRAL_PROGRAM.EARN_30, {amount})}
            </Text>
          </Badge>
        }
      />
    </SafeAreaView>
  );
};

const LeftIcon = styled(StyledIcon)({
  marginTop: 8,
  marginBottom: 18,
  marginLeft: 14,
});

const styles = StyleSheet.create({
  layout: {
    width: '100%',
    height: '100%',
    backgroundColor: appColors.drawerBackground,
  },
  badgeText: {
    color: appColors.white,
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  flexContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
