import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React from 'react';
import {
  Animated,
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {useSpinner} from '../../components';
import {Constants, Screens, Strings, appColors} from '../../constants';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useReferrals} from '../../lib/hooks/useReferrals';
import {MyRewardsScreen} from '../../screens/ReferralProgram/MyRewardsScreen';
import {Props as MyRewardsScreenProps} from '../../screens/ReferralProgram/MyRewardsScreen';
import {ReferAFriendScreen} from '../../screens/ReferralProgram/ReferAFriendScreen';
import {Badge} from '../../shims/Badge';

const screenWidth = Constants.DIMENSIONS.WIDTH;

interface TabLabelProps {
  focused: boolean;
  title: string;
  style?: StyleProp<ViewStyle>;
}

export type ReferralTabParamList = {
  [Screens.ReferAFriend]: undefined;
  [Screens.MyRewards]: MyRewardsScreenProps;
};

const ReferralProgramTabNavigator =
  createMaterialTopTabNavigator<ReferralTabParamList>();

const TabLabel = ({focused, title, style}: TabLabelProps) => {
  const underlineWidth = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(underlineWidth, {
      toValue: focused ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [focused, underlineWidth]);

  return (
    <View {...{style}}>
      <Text
        numberOfLines={1}
        style={[
          styles.labelText,
          {
            opacity: focused ? 1 : 0.5,
            fontFamily: focused ? 'Roboto-Bold' : 'Roboto-Regular',
          },
        ]}>
        {title}
      </Text>

      <Animated.View
        style={[
          styles.underline,
          {
            width: underlineWidth.interpolate({
              inputRange: [0, 1],
              outputRange: ['0%', '100%'],
            }),
          },
        ]}
      />
    </View>
  );
};

export const ReferralProgramTab = () => {
  const tabBarTranslateY = React.useRef(new Animated.Value(40)).current;

  const {isLoading, unused} = useReferrals();

  useAdaptiveSafeArea();

  const initialTouchY = React.useRef(0);
  const currentTouchY = React.useRef(0);
  const initialTouchX = React.useRef(0);
  const currentTouchX = React.useRef(0);

  const handleTouchStart = (event: GestureResponderEvent) => {
    initialTouchY.current = event.nativeEvent.pageY;
    initialTouchX.current = event.nativeEvent.pageX;
  };

  const handleTouchMove = (event: GestureResponderEvent) => {
    currentTouchY.current = event.nativeEvent.pageY;
    currentTouchX.current = event.nativeEvent.pageX;

    const deltaY = currentTouchY.current - initialTouchY.current;
    const deltaX = currentTouchX.current - initialTouchX.current;

    // If horizontal move > vertical move, do nothing (it's a swipe)
    if (Math.abs(deltaX) > Math.abs(deltaY)) return;

    if (Math.abs(deltaY) < 5) return;

    const toValue = deltaY > 0 ? 40 : -64;

    Animated.timing(tabBarTranslateY, {
      toValue,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  useSpinner({
    isVisible: isLoading,
    text: Strings.LOADING_DATA,
    color: appColors.white,
  });

  return (
    <View
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      style={styles.layout}>
      {!isLoading && (
        <ReferralProgramTabNavigator.Navigator
          screenOptions={{
            tabBarStyle: {
              ...styles.tabBar,
              marginTop: tabBarTranslateY,
            },
            tabBarLabelStyle: styles.label,
            tabBarIndicatorStyle: styles.indicator,
            tabBarGap: 24,
            tabBarBounces: false,
            tabBarScrollEnabled: false,
          }}>
          <ReferralProgramTabNavigator.Screen
            name={Screens.ReferAFriend}
            component={ReferAFriendScreen}
            options={{
              tabBarLabel: ({focused}) => (
                <TabLabel
                  {...{focused}}
                  style={[
                    styles.tabBarLabel,
                    {
                      left: -getLeftTabBarPositioning(),
                    },
                  ]}
                  title={Strings.REFERRAL_PROGRAM.REFER_A_FRIEND}
                />
              ),
            }}
          />

          <ReferralProgramTabNavigator.Screen
            name={Screens.MyRewards}
            component={MyRewardsScreen}
            options={{
              tabBarLabel: ({focused}) => (
                <View
                  style={[
                    styles.badgeBar,
                    styles.tabBarLabel,
                    {
                      right: -getRightTabBarPositioning({
                        withBadge: unused.length > 0,
                      }),
                    },
                  ]}>
                  <TabLabel
                    title={Strings.REFERRAL_PROGRAM.MY_REWARDS}
                    {...{focused}}
                  />

                  {unused.length > 0 && (
                    <Badge style={styles.badge}>
                      <Text style={styles.badgeText}>{unused.length}</Text>
                    </Badge>
                  )}
                </View>
              ),
            }}
          />
        </ReferralProgramTabNavigator.Navigator>
      )}
    </View>
  );
};

const getLeftTabBarPositioning = () => (screenWidth > 400 ? 36 : 46);

const getRightTabBarPositioning = ({
  withBadge = false,
}: {
  withBadge?: boolean;
}) => (withBadge ? (screenWidth > 400 ? 36 : 45) : screenWidth > 400 ? 14 : 20);

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: appColors.black,
  },
  tabBar: {
    overflow: 'hidden',
    backgroundColor: appColors.black,
  },
  tabBarLabel: {
    position: 'absolute',
    top: -16,
  },
  underline: {
    height: 1,
    backgroundColor: appColors.white,
    marginTop: 6,
    opacity: 0.5,
  },
  labelText: {
    fontSize: 18,
    color: appColors.white,
    textTransform: 'uppercase',
    flexShrink: 1,
  },
  badgeBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  badge: {marginBottom: 6, marginLeft: 6, height: 25, width: 25},
  indicator: {
    display: 'none',
  },
  label: {
    color: appColors.white,
    fontSize: 18,
    lineHeight: 16,
    width: '100%',
    backgroundColor: appColors.red,
  },
  badgeText: {
    color: appColors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
  },
});
