import React from 'react';
import {Text, View} from 'react-native';
import {useDispatch} from 'react-redux';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
} from '../../analytics/Analytics';
import {brainSignal} from '../../assets/images';
import {LinearGradientBackground, StyledButton} from '../../components';
import {
  Messages,
  Screens,
  Strings,
  appColors,
  fillStyle,
} from '../../constants';
import {updateAppFlags} from '../../lib/redux/appFlagsSlice';
import styled from '../../lib/styled';
import {ControlCenterNavigatorScreenProps} from '../../navigation/navigators/HomeDrawerNavigator';
import {HeatProfileEditNavigator} from '../../navigation/navigators/RootStackNavigator';
import {toMoodLightLibrary} from '../../navigation/navigators/util';
import {Image} from '../../shims/ImageWithFilter';
import {SafeAreaView} from '../../shims/SafeAreaView';

type Props =
  | HeatProfileEditNavigator<typeof Screens.EpilepsyWarning>
  | ControlCenterNavigatorScreenProps<typeof Screens.EpilepsyWarning>;

export const EpilepsyWarningScreen = ({route, navigation}: Props) => {
  const dispatch = useDispatch();

  const redirect = route.params?.redirect ?? toMoodLightLibrary.value;

  const onUnderstandButtonPress = React.useCallback(() => {
    dispatch(updateAppFlags({hasSeenEpilepsyWarning: true}));

    Analytics.shared().logCustomEventAction(
      CustomEventAction.EPILEPSY_WARNING_VIEW,
      CustomCategory.WARNING,
    );

    navigation.pop();
    // @ts-expect-error because of the screen being part of two different stacks
    navigation.navigate(...redirect);
  }, [navigation, redirect]);

  return (
    <LinearGradientBackground colors={['#7A24F4', '#5F12F5']}>
      <ScreenContainer>
        <TopContainer>
          <Image
            source={brainSignal}
            resizeMode={'contain'}
            style={fillStyle}
          />
        </TopContainer>

        <BottomContainer>
          <View>
            <TitleText>{Messages.EPILEPSY_WARNING_TITLE}</TitleText>

            <DescriptionText>
              {Messages.EPILEPSY_WARNING_DESCRIPTION}
            </DescriptionText>
          </View>

          <StyledButton
            title={Strings.I_UNDERSTAND}
            onPress={onUnderstandButtonPress}
          />
        </BottomContainer>
      </ScreenContainer>
    </LinearGradientBackground>
  );
};

const ScreenContainer = styled(SafeAreaView)({
  ...fillStyle,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const InnerContainer = styled(View)({
  ...fillStyle,
  display: 'flex',
  flexDirection: 'column',
});

const TopContainer = styled(InnerContainer)({justifyContent: 'flex-end'});

const BottomContainer = styled(InnerContainer)({
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 40,
  paddingHorizontal: 30,
});

const TitleText = styled(Text)({
  fontFamily: 'BigShouldersDisplay-Bold',
  fontSize: 44,
  color: appColors.white,
  letterSpacing: 1,
  marginBottom: 29,
  textTransform: 'uppercase',
  includeFontPadding: false,
  textAlign: 'center',
  fontWeight: '400',
});

const DescriptionText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontSize: 18,
  lineHeight: 28,
  color: appColors.white,
  letterSpacing: 0.5,
  includeFontPadding: false,
  textAlign: 'center',
  fontWeight: '400',
});
