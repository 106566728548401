import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {Dimensions, LayoutChangeEvent, TextInput, View} from 'react-native';
import {useSelector} from 'react-redux';

import {
  AppText,
  PeakImageWithStaticChamber,
  StatusDisplay,
  StyledButton,
  SwitchableBackground,
} from '../../components';
import {
  Alerts,
  Constants,
  ErrorMessages,
  Messages,
  Navigators,
  Screens,
  Strings,
  appColors,
} from '../../constants';
import {appLog} from '../../lib/Logger';
import {ConnectionPhase} from '../../lib/ble2/v2/BleManager/BleManagerBase';
import {ConnectionError} from '../../lib/ble2/v2/types';
import {checkStringBytes} from '../../lib/checkStringBytes';
import {getIsPup} from '../../lib/getIsPup';
import {useAppDispatch, useBackPress} from '../../lib/hooks';
import {useProgress} from '../../lib/hooks/useProgress';
import {
  bleConnectionStatusSelector,
  currentDeviceSelector,
  devicesSelector,
  setCanReconnect,
} from '../../lib/redux/bleSlice';
import {
  bleDisconnectDevice,
  bleReconnectDevice,
  bleScanAndConnect,
  bleWriteDeviceName,
} from '../../lib/redux/thunk';
import styled from '../../lib/styled';
import {useSafeArea} from '../../lib/useSafeArea';
import {useTheme} from '../../lib/useTheme';
import {addOpacityToColorHex} from '../../lib/utilityFunctions';
import {showBootloaderAlert} from '../../lib/utilityFunctions/showBootloaderAlert';
import {MainNavigatorScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {RedirectionParam} from '../../navigation/navigators/params';
import {toDevicesList, toHome} from '../../navigation/navigators/util';
import {Image} from '../../shims/ImageWithFilter';
import {SafeAreaView} from '../../shims/SafeAreaView';
import {
  activateScreenAwake,
  deactivateScreenAwake,
} from '../../shims/screenAwake';
import {PermissionError} from '../../src/services/PermissionError';
import {locationService} from '../../src/services/location';
import {
  defaultTheme,
  desertTheme,
  flourishTheme,
  guardianTheme,
  stormTheme,
} from '../../themes';
import {Info, Title} from './components';

const FOOTER_HEIGHT = 100;
const FOOTER_BOTTOM_MARGIN = 20;
const {width, height: windowHeight} = Dimensions.get('window');
const {
  PEAK_IMAGE_ORIGINAL_DIMENSIONS,
  PEAK_NAME_MAX_LENGTH,
  PEAK_NAME_PUP_MAX_LENGTH,
  IS_WEB,
} = Constants;
const {connectedGreen, errorColor, green, pureBlue, red, black, white} =
  appColors;

type ConnectionScreenPhase = ConnectionPhase | 'starting' | 'name_device';

export interface Props extends RedirectionParam {
  deviceId?: string;
  redirectedFromLimitedEdition?: boolean;
}

type ScreenProps = MainNavigatorScreenProps<typeof Screens.Connect>;

export const ConnectScreen = ({route, navigation}: ScreenProps) => {
  const isFocused = useIsFocused();
  const themeFromDevice = useTheme();

  const isLimitedEdition = !!themeFromDevice.limitedEditionModalScreenTheme;
  const isGuardian = themeFromDevice === guardianTheme;
  const isDesert = themeFromDevice === desertTheme;
  const isFlourish = themeFromDevice === flourishTheme;
  const isStorm = themeFromDevice === stormTheme;

  const dispatch = useAppDispatch();
  const redirectedFromLimitedEdition =
    route.params?.redirectedFromLimitedEdition;
  const deviceIdToReconnect = route.params?.deviceId;
  const redirect = route.params?.redirect;

  const device = useSelector(currentDeviceSelector);
  const savedDevices = useSelector(devicesSelector);
  const connected = useSelector(bleConnectionStatusSelector);
  const isPup = getIsPup(connected);

  const currentTheme = redirectedFromLimitedEdition
    ? themeFromDevice
    : defaultTheme;

  const [peakName, setPeakName] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState<string>();
  const [progress, setProgress, resetProgress] =
    useProgress<ConnectionScreenPhase>({
      value: 0,
      data: 'starting',
      duration: 0,
    });

  const [bleState, setBleState] = React.useState<
    ConnectionError | PermissionError | string
  >();

  const [peakImageSize, setPeakImageSize] = React.useState({height: 0, y: 0});

  const {bottom} = useSafeArea();

  const connect = React.useCallback(async () => {
    try {
      setBleState(undefined);
      resetProgress();

      const onBootloader = async () =>
        showBootloaderAlert(() =>
          navigation.navigate(Navigators.MainNavigator, {
            screen: Navigators.HomeDrawerNavigator,
            params: {
              screen: Navigators.HomeEmulatedDrawer,
              params: {screen: Screens.FirmwareUpdating},
            },
          }),
        );

      if (deviceIdToReconnect) {
        await dispatch(bleDisconnectDevice()).unwrap();
        await dispatch(
          bleReconnectDevice({
            peripheralId: deviceIdToReconnect,
            onProgress: setProgress,
            onBootloader,
          }),
        ).unwrap();
      } else {
        await dispatch(
          bleScanAndConnect({onProgress: setProgress, onBootloader}),
        ).unwrap();
      }
    } catch (error: any) {
      appLog.error('Connection failed.', {error});

      switch (error.message) {
        case ConnectionError.DEVICE_NOT_FOUND:
        case ConnectionError.IOS_BLUETOOTH_DISABLED:
        case ConnectionError.IOS_CONNECTION_NOT_FOUND:
        case ConnectionError.PAIRING_ERROR:
        case ConnectionError.IOS_DEVICE_FORGOTTEN:
        case ConnectionError.USER_CANCELLED:
        case ConnectionError.WEB_USER_CANCELLED:
        case ConnectionError.IOS_BONDING_ERROR:
        case ConnectionError.ANDROID_BONDING_ERROR:
        case ConnectionError.IN_BOOTLOADER_STATE:
        case PermissionError.LocationDisabled:
        case PermissionError.LocationCanceled:
        case PermissionError.LocationDismissed:
        case PermissionError.LocationRequiresAction:
        case PermissionError.BluetoothDisabled:
        case PermissionError.BluetoothDenied:
        case PermissionError.BluetoothCanceled:
        case PermissionError.BluetoothDismissed:
        case PermissionError.BluetoothRequiresAction:
          return setBleState(error.message);
      }

      return setBleState(ConnectionError.CONNECTION_ERROR);
    }
  }, [deviceIdToReconnect]);

  React.useEffect(() => {
    if (progress.value < 1 || progress.data !== 'done') return;

    if (deviceIdToReconnect) {
      navigation.navigate(...(redirect ?? toDevicesList));

      return;
    }

    if (isLimitedEdition)
      navigation.navigate(Screens.LimitedEditionModal, {redirect});

    setProgress({data: 'name_device'});
  }, [progress, deviceIdToReconnect, isLimitedEdition, redirect, setProgress]);

  React.useEffect(() => {
    dispatch(setCanReconnect(false));
    activateScreenAwake();

    if (!redirectedFromLimitedEdition) connect().catch(() => void 0);

    return () => {
      deactivateScreenAwake();
    };
  }, [redirectedFromLimitedEdition, connect]);

  useBackPress(
    React.useCallback(() => {
      if (!device) return true;

      navigation.navigate(...(redirect ?? toDevicesList));

      return true;
    }, [redirect, device]),
  );

  const saveDeviceName = async (name: string) => {
    name = name.trim().toUpperCase();

    if (!name.length) return setErrorMsg(ErrorMessages.DEVICE_NAME_EMPTY);

    if (errorMsg) return;

    await dispatch(bleWriteDeviceName({name})).unwrap();

    navigation.replace(...(redirect ?? toHome));
  };

  const getTitle = (): string => {
    switch (bleState) {
      case ConnectionError.DEVICE_NOT_FOUND:
      case ConnectionError.IOS_CONNECTION_NOT_FOUND:
        return ErrorMessages.DEVICE_NOT_FOUND_TITLE;
      case ConnectionError.CONNECTION_ERROR:
        return ErrorMessages.CONNECTION_ERROR_TITLE;
      case ConnectionError.USER_CANCELLED:
      case ConnectionError.WEB_USER_CANCELLED:
        return ErrorMessages.CONNECTION_CANCELED_ERROR_TITLE;
      case ConnectionError.PAIRING_ERROR:
        return ErrorMessages.PAIRING_ERROR_TITLE;
      case ConnectionError.IOS_DEVICE_FORGOTTEN:
        return ErrorMessages.IOS_FORGOTTEN_DEVICE_TITLE;
      case ConnectionError.IOS_BONDING_ERROR:
      case ConnectionError.ANDROID_BONDING_ERROR:
        return ErrorMessages.BONDING_ERROR_TITLE;
      case ConnectionError.IN_BOOTLOADER_STATE:
        return ErrorMessages.BOOTLOADER_TITLE;
      case PermissionError.LocationDisabled:
        return ErrorMessages.LOCATION_SERVICES_DISABLED_TITLE;
      case PermissionError.LocationCanceled:
      case PermissionError.LocationRequiresAction:
      case PermissionError.LocationDismissed:
        return ErrorMessages.LOCATION_ACCESS_DENIED_TITLE;
      case PermissionError.BluetoothDisabled:
        return ErrorMessages.BLUETOOTH_SERVICES_DISABLED_TITLE;
      case PermissionError.BluetoothDenied:
      case PermissionError.BluetoothCanceled:
      case PermissionError.BluetoothDismissed:
      case PermissionError.BluetoothRequiresAction:
        return ErrorMessages.BLUETOOTH_PERMISSION_DENIED_TITLE;
    }

    switch (progress.data) {
      case 'starting':
      case 'scanning':
        return Messages.SEARCHING_FOR_DEVICE_TITLE;
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'waiting_for_ota':
      case 'waiting_for_peak':
      case 'setting_up':
      case 'initializing':
        return Messages.CONNECTION_FOUND_TITLE;
      case 'done':
        return progress.value === 1
          ? Alerts.SUCCESS
          : Messages.CONNECTION_FOUND_TITLE;
      case 'name_device':
        return progress.value === 1
          ? Messages.NAME_YOUR_PEAK_TITLE
          : Messages.CONNECTION_FOUND_TITLE;
    }
  };

  const getPeakColor = () => {
    if (bleState) return red;

    switch (progress.data) {
      case 'scanning':
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'waiting_for_ota':
      case 'waiting_for_peak':
      case 'setting_up':
      case 'initializing':
        return pureBlue;
      case 'done':
        return green;
      case 'name_device':
        if (!isLimitedEdition) return green;
        if (isGuardian || isDesert || isFlourish || isStorm) return black;
        break;
    }

    return white;
  };

  const getStatusBarStatus = (): string => {
    switch (progress.data) {
      case 'starting':
      case 'scanning':
        return Strings.SEARCHING.toUpperCase();
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
        return Strings.INITIALIZING.toUpperCase();
      case 'waiting_for_ota':
      case 'waiting_for_peak':
      case 'connecting':
      case 'setting_up':
      case 'initializing':
        return Strings.PAIRING.toUpperCase();
      case 'done':
      case 'name_device':
        return Strings.CONNECTED.toUpperCase();
    }
  };

  const getInstruction = (): string => {
    switch (bleState) {
      case ConnectionError.DEVICE_NOT_FOUND:
      case ConnectionError.IOS_CONNECTION_NOT_FOUND:
        return ErrorMessages.DEVICE_NOT_FOUND_MSG;
      case ConnectionError.CONNECTION_ERROR:
        return ErrorMessages.CONNECTION_ERROR_MSG;
      case ConnectionError.USER_CANCELLED:
      case ConnectionError.WEB_USER_CANCELLED:
        return ErrorMessages.CONNECTION_CANCELED_ERROR_MSG;
      case ConnectionError.PAIRING_ERROR:
        return ErrorMessages.PAIRING_ERROR_MSG;
      case ConnectionError.IOS_DEVICE_FORGOTTEN:
        return ErrorMessages.IOS_FORGOTTEN_DEVICE_MSG;
      case ConnectionError.IOS_BONDING_ERROR:
      case ConnectionError.ANDROID_BONDING_ERROR:
        return ErrorMessages.BONDING_ERROR_MSG;
      case ConnectionError.IN_BOOTLOADER_STATE:
        return ErrorMessages.BOOTLOADER_MSG;
      case PermissionError.LocationDisabled:
        return ErrorMessages.LOCATION_SERVICES_DISABLED_MSG;
      case PermissionError.LocationCanceled:
      case PermissionError.LocationRequiresAction:
      case PermissionError.LocationDismissed:
        return locationService.required
          ? ErrorMessages.LOCATION_ACCESS_DENIED_12_MSG
          : ErrorMessages.LOCATION_ACCESS_DENIED_PRE_12_MSG;
      case PermissionError.BluetoothDisabled:
        return ErrorMessages.BLUETOOTH_SERVICES_DISABLED_MSG;
      case PermissionError.BluetoothDenied:
      case PermissionError.BluetoothCanceled:
      case PermissionError.BluetoothDismissed:
      case PermissionError.BluetoothRequiresAction:
        return IS_WEB
          ? ErrorMessages.BLUETOOTH_PERMISSION_DENIED_WEB_MSG
          : ErrorMessages.BLUETOOTH_PERMISSION_DENIED_MSG;
    }

    switch (progress.data) {
      case 'starting':
      case 'scanning':
        return Messages.PAIRING_INSTRUCTIONS.pairing.body;
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'waiting_for_ota':
      case 'waiting_for_peak':
      case 'setting_up':
      case 'initializing':
        return Messages.CONNECTION_FOUND_MSG;
      case 'done':
        return Messages.CONNECTION_SUCCESS_DONE_MSG;
      case 'name_device':
        return Messages.NAME_YOUR_PEAK_DONE_MSG;
    }
  };

  const getLoadingHaloProps = () => {
    if (bleState) return {color: '#FF3B30', percentage: 1};

    switch (progress.data) {
      case 'done':
      case 'name_device':
        return {
          color:
            progress.target === 1 ? connectedGreen : currentTheme.primaryColor,
          percentage: progress.value,
        };
    }

    return {color: currentTheme.primaryColor, percentage: progress.value};
  };

  const makeGroundLayerStyle = () => {
    const {groundLayer} = currentTheme.connectScreenTheme;
    if (!groundLayer) {
      // use old logic if no groundLayer theme values are defined
      return {bottom: -(FOOTER_HEIGHT + FOOTER_BOTTOM_MARGIN + bottom)};
    }
    if (peakImageSize.height === 0) {
      // hide Ground Layer until Peak Image is rendered
      return {width: 0, height: 0};
    }

    const scaledPeakImageSizeWidth =
      peakImageSize.height *
      (PEAK_IMAGE_ORIGINAL_DIMENSIONS.width /
        PEAK_IMAGE_ORIGINAL_DIMENSIONS.height);
    const scaledGroundLayerImageWidth =
      scaledPeakImageSizeWidth *
      groundLayer.groundLayerImageWidthToPeakImageWidthRatio;
    const scaledGroundLayerImageHeight =
      scaledGroundLayerImageWidth *
      (groundLayer.groundLayerImageOriginalHeight /
        groundLayer.groundLayerImageOriginalWidth);
    const groundLayerImageTop =
      peakImageSize.height * groundLayer.normalizedPeakImageYOffset -
      scaledGroundLayerImageHeight * groundLayer.normalizedGroundLayerYOffset;

    return {
      top: groundLayerImageTop,
      width: scaledGroundLayerImageWidth,
      height: scaledGroundLayerImageHeight,
    };
  };

  const onGroundLayout = React.useCallback((e: LayoutChangeEvent) => {
    const {height, y} = e.nativeEvent.layout;
    setPeakImageSize({height, y});
  }, []);

  // scale bg image up to remove white gap for guardian theme
  const scaleBackgroundForGuardian = () => {
    return isGuardian && progress.data === 'name_device'
      ? 1 + windowHeight * 0.0005
      : 1;
  };

  return (
    <SwitchableBackground
      background={currentTheme.connectScreenTheme.background}
      imageStyle={{transform: [{scale: scaleBackgroundForGuardian()}]}}>
      <SafeAreaView style={{flex: 1}}>
        <HeaderContainer>
          <TopHeaderContainer>
            <Title
              numberOfLines={2}
              style={{
                color: currentTheme.primaryColor,
                flex: 1,
                marginBottom:
                  windowHeight <= Constants.SCREEN_HEIGHT.GALAXY_S8 ? 5 : 10,
              }}>
              {getTitle()}
            </Title>

            {!!bleState && savedDevices.length >= 1 && (
              <CancelButton
                onPress={() =>
                  navigation.navigate(...(redirect ?? toDevicesList))
                }>
                {Strings.CANCEL}
              </CancelButton>
            )}
          </TopHeaderContainer>

          <Info style={{color: currentTheme.primaryColor, flex: 1}}>
            {getInstruction()}
          </Info>

          {progress.value === 1 &&
            progress.data === 'name_device' &&
            isFocused && (
              <PeakNameContainer
                style={{
                  backgroundColor: addOpacityToColorHex(
                    currentTheme.connectScreenTheme.peakNameBackgroundColor,
                    0.4,
                  ),
                }}>
                <PeakNameInput
                  style={{
                    color:
                      currentTheme.connectScreenTheme.peakNameTextColor ??
                      currentTheme.primaryColor,
                  }}
                  value={peakName}
                  maxLength={
                    isPup ? PEAK_NAME_PUP_MAX_LENGTH : PEAK_NAME_MAX_LENGTH
                  }
                  editable
                  // Workaround for this issue: https://github.com/facebook/react-native/issues/11068
                  keyboardType="visible-password"
                  onSubmitEditing={() => saveDeviceName(peakName)}
                  returnKeyType="go"
                  keyboardAppearance="dark"
                  onLayout={() =>
                    device?.name && setPeakName(device.name.toUpperCase())
                  }
                  onChangeText={text => {
                    if (checkStringBytes(text.trim(), 'device', isPup)) {
                      setErrorMsg(undefined);
                      setPeakName(text.toUpperCase());
                    } else {
                      setErrorMsg(ErrorMessages.DEVICE_NAME_LONG);
                    }
                  }}
                  autoFocus
                />
                {!!errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
              </PeakNameContainer>
            )}
        </HeaderContainer>

        <BodyContainer onLayout={onGroundLayout}>
          {!!currentTheme.connectScreenTheme.groundLayerImage && (
            <GroundLayerContainer style={makeGroundLayerStyle()}>
              <Image
                source={currentTheme.connectScreenTheme.groundLayerImage}
                resizeMode="cover"
                style={{flex: 1, width: '100%'}}
              />
            </GroundLayerContainer>
          )}

          <PeakImageWithStaticChamber
            colorSource={getPeakColor()}
            style={{width: '100%', height: '80%'}}
            haloProps={
              isLimitedEdition && progress.data === 'name_device'
                ? undefined
                : {
                    ...getLoadingHaloProps(),
                    style: {marginTop: -width * 0.2},
                  }
            }
            useDefaultTheme={currentTheme === defaultTheme}
          />
        </BodyContainer>

        <FooterContainer>
          <StatusDisplay
            status={bleState ? '' : getStatusBarStatus()}
            percentage={
              isLimitedEdition && progress.data === 'name_device'
                ? undefined
                : Math.round(progress.value * 100)
            }
            error={bleState ? 'PLEASE TRY AGAIN' : ''}
            theme={currentTheme}
          />

          {progress.data === 'name_device' ? (
            <StyledButton
              title={Strings.NEXT}
              onPress={() => saveDeviceName(peakName)}
              theme={currentTheme.styledButtonTheme}
            />
          ) : (
            !!bleState && (
              <StyledButton
                title={
                  bleState === PermissionError.BluetoothDisabled
                    ? Strings.ALLOW
                    : Strings.CONTINUE
                }
                onPress={() => connect()}
                theme={currentTheme.styledButtonTheme}
              />
            )
          )}
        </FooterContainer>
      </SafeAreaView>
    </SwitchableBackground>
  );
};

const HeaderContainer = styled(View)({
  height: 180,
  zIndex: 11,
});

const TopHeaderContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CancelButton = styled(AppText)({
  color: appColors.gray,
  fontSize: 14,
  textAlignVertical: 'center',
  marginRight: 16,
  marginTop: 15,
});

const PeakNameContainer = styled(View)({
  height: 62,
  width: '100%',
  alignItems: 'center',
  marginTop: 15,
  paddingHorizontal: 15,
});

const PeakNameInput = styled(TextInput)({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 20,
  letterSpacing: 0.18,
});

const ErrorMessage = styled(AppText)({
  color: errorColor,
  fontSize: 14,
  position: 'absolute',
  bottom: 0,
});

const BodyContainer = styled(View)({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: 5,
});

const GroundLayerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  top: '80%',
});

const FooterContainer = styled(View)({
  width: '100%',
  height: FOOTER_HEIGHT,
  bottom: 0,
  marginBottom: FOOTER_BOTTOM_MARGIN,
  alignItems: 'center',
  justifyContent: 'space-evenly',
  zIndex: 6,
});
