/**
 * We didn't implement FCM for web browsers (e.g. Chrome) because they are used only during development.
 * It would be nice if it would work, but we couldn't make it work: the firebase service worker is
 * being loaded in the Path Browser as well, which is undesirable.
 * Leaving here the implementation in case we figure it out how to fix it.
 */
// import {initializeApp} from 'firebase/app';
// import {
//   Messaging,
//   deleteToken,
//   getMessaging,
//   getToken,
//   isSupported,
// } from 'firebase/messaging';
import {Constants, Environment} from '../../../constants';
import {appLog} from '../../../lib/Logger';
import {DisposableCallback} from './DisposableCallback';
import {IPushService, Message, MessageType, PushService} from './IPushService';

const navigator: Puffco.Navigator = window.navigator;

class BrowserFcmPushService
  extends PushService<undefined>
  implements IPushService
{
  // private _messaging?: Messaging;

  constructor() {
    super();
  }

  // protected async request(): Promise<void> {
  //   const supported = await isSupported();

  //   if (!supported) throw new Error('Push notifications are not supported.');

  //   const permission = await Notification.requestPermission();

  //   if (permission !== 'granted')
  //     throw new Error('Push notifications permissions not granted.');
  // }

  async getInitialUrl(): Promise<string | undefined> {
    return;
  }

  protected getMessage(_notification: undefined): Message | undefined {
    return undefined;
  }

  onTokenUpdated(): () => void {
    return () => void 0;
  }

  // protected get messaging() {
  //   if (this._messaging) return this._messaging;

  //   const app = initializeApp(Environment.firebase, {
  //     automaticDataCollectionEnabled: false,
  //   });

  //   this._messaging = getMessaging(app);

  //   return this._messaging;
  // }

  async getToken(): Promise<string | undefined> {
    appLog.info('Get push token.');

    return;

    // await this.request();

    // return await getToken(this.messaging);
  }

  async deleteToken() {
    appLog.info('Delete push token.');

    // const supported = await isSupported();

    // if (!supported) return;

    // await deleteToken(this.messaging);
  }

  supported(): boolean {
    return false;
  }

  async enabled(): Promise<boolean> {
    return false;
  }

  async openSettings(): Promise<void> {}
}

class ApnsPathPushService
  extends PushService<undefined>
  implements IPushService
{
  constructor(private notifications: Puffco.Notifications) {
    super();
  }

  protected getMessage(_notification: undefined): Message | undefined {
    return undefined;
  }

  async getInitialUrl(): Promise<string | undefined> {
    return;
  }

  onTokenUpdated(): () => void {
    return () => void 0;
  }

  async getToken(): Promise<string | undefined> {
    appLog.info('Get push token.');

    return this.notifications.status();
  }

  async deleteToken(): Promise<void> {
    appLog.info('Delete push token.');
  }

  supported(): boolean {
    return false;
  }

  async enabled(): Promise<boolean> {
    return false;
  }

  async openSettings(): Promise<void> {}
}

enum AppState {
  Active = 0,
  Inactive = 1,
  Background = 2,
}

interface PathEventPayload {
  'fcm.token.updated': {token: string};
  'fcm.notification.received': {payload: unknown; state: AppState};
  'fcm.notification.opened': {payload: unknown; state: AppState};
}

type PathEvent<T extends keyof PathEventPayload> = {
  type: T;
} & PathEventPayload[T];

const isEvent = (
  event: unknown,
): event is PathEvent<keyof PathEventPayload> => {
  return (
    !!event &&
    typeof event === 'object' &&
    'type' in event &&
    typeof event['type'] === 'string'
  );
};

class FcmPathPushService extends PushService<unknown> implements IPushService {
  private onToken = new DisposableCallback<string>();
  private initialMessage?: Message;

  constructor(
    private fcm: Required<Puffco.FcmNotification>,
    private settings: Puffco.NotificationSettings,
  ) {
    super();

    this.fcm.onEvents((event, queued) => {
      appLog.info('Notification received', {event, queued});

      if (!isEvent(event)) return;

      switch (event.type) {
        case 'fcm.token.updated':
          this.onToken.invoke((event as PathEvent<'fcm.token.updated'>).token);
          return;
        case 'fcm.notification.received': {
          const pathEvent = event as PathEvent<'fcm.notification.received'>;

          const message = this.getMessage(pathEvent.payload);
          const type =
            pathEvent.state === AppState.Active ? 'foreground' : 'background';

          this.onMessage(message, type).catch(() => void 0);

          return;
        }
        case 'fcm.notification.opened': {
          const pathEvent = event as PathEvent<'fcm.notification.opened'>;

          const message = this.getMessage(pathEvent.payload);

          this.onMessage(message, 'open').catch(() => void 0);

          this.initialMessage = this.initialMessage ?? message;

          return;
        }
      }
    });
  }

  protected async onMessage(message: Message | undefined, type: MessageType) {
    await super.onMessage(message, type);

    if (type !== 'open') return;
    if (!this.isValidPayload(message?.data)) return;

    switch (message?.data.type) {
      case 'url': {
        const url = this.getUrlFromPayload(message.data);

        if (!url) return;

        this.onAppOpenCallback.invoke(url);
      }
    }
  }

  protected getMessage(message: unknown): Message | undefined {
    const alert =
      message &&
      typeof message === 'object' &&
      'aps' in message &&
      !!message.aps &&
      typeof message.aps === 'object' &&
      'alert' in message.aps &&
      !!message.aps.alert &&
      typeof message.aps.alert === 'object'
        ? message.aps.alert
        : undefined;

    const notification = alert
      ? {
          ...('title' in alert && typeof alert.title === 'string'
            ? {title: alert.title}
            : {}),
          ...('body' in alert && typeof alert.body === 'string'
            ? {body: alert.body}
            : {}),
        }
      : undefined;

    return {
      notification,
      data: message && typeof message === 'object' ? message : undefined,
    };
  }

  protected async waitForInitialMessage(wait: number) {
    const now = Date.now();

    const loop = (
      resolve: (m?: Message) => void,
      reject: (e: Error) => void,
    ) => {
      if (this.initialMessage) return resolve(this.initialMessage);
      if (Date.now() >= now + wait) return resolve();

      setTimeout(() => loop(resolve, reject), 250);
    };

    return await new Promise(loop);
  }

  async getInitialUrl(): Promise<string | undefined> {
    const initialMessage = await this.waitForInitialMessage(1000);

    if (!initialMessage) return;

    return this.getUrlFromPayload(initialMessage.data);
  }

  onTokenUpdated(callback: (token: string) => void): () => void {
    return this.onToken.register(callback);
  }

  async getToken(): Promise<string | undefined> {
    appLog.info('Get push token.');

    return await this.fcm.getToken({
      type: 'fcm',
      config: Environment.firebase,
    });
  }

  async deleteToken(): Promise<void> {
    appLog.info('Delete push token.');

    await this.fcm.deleteToken();
  }

  supported(): boolean {
    return !!this.settings.isEnabled;
  }

  async enabled(): Promise<boolean> {
    return this.settings.isEnabled?.() ?? false;
  }

  async openSettings(): Promise<void> {
    await this.settings.openSettings?.();
  }
}

const fcmSupported = (
  n: Puffco.FcmNotification,
): n is Required<Puffco.FcmNotification> => {
  return !!n.getToken && !!n.deleteToken && !!n.onEvents;
};

export const pushService: IPushService =
  navigator.notifications && Constants.IS_USING_PATH_BROWSER
    ? fcmSupported(navigator.notifications)
      ? new FcmPathPushService(navigator.notifications, navigator.notifications)
      : new ApnsPathPushService(navigator.notifications)
    : new BrowserFcmPushService();
