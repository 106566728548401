export {default as addPhoto} from './add-photo-web.png';
export {default as backgroundAccountCreated} from './background-account-created.jpg';
export {default as backgroundCleaningReminder} from './background-cleaning-reminder.png';
export {default as backgroundFlourishMoodlightInterstitial} from './background-flourish-moodlight-interstitial.jpg';
export {default as backgroundMarketOptInEarlyAccess} from './background-early-access.jpg';
export {default as backgroundForgotPassword} from './background-forgot-password.jpeg';
export {default as backgroundLogin} from './background-login.jpg';
export {default as backgroundOpal} from './background-main-opal.png';
export {default as background} from './background-main.png';
export {default as backgroundMarketOptInConfirmed} from './background-market-opt-in-confirmed.jpg';
export {default as backgroundMarketOptIn} from './background-market-opt-in.png';
export {default as backgroundMetricsAlertScreen} from './background-metrics-alert-screen.jpeg';
export {default as backgroundUpdateUsernameNew} from './background-new-update-username.jpg';
export {default as backgroundPasswordConfirmation} from './background-password-confirmation.jpg';
export {default as backgroundPasswordResetRequest} from './background-password-reset-request.jpeg';
export {default as backgroundProductRelease} from './background-product-release.jpg';
export {default as backgroundRegister} from './background-register.jpg';
export {default as backgroundUpdateUsername} from './background-update-username.jpg';
export {default as backgroundV3ChamberScreen} from './background-v3-chamber-screen.jpg';
export {default as backgroundVaporScreen} from './background-vapor-screen.jpg';
export {default as backgroundWelcome} from './background-welcome.jpg';
export {default as backgroundXLUnlockedAlertScreen} from './background-xl-unlocked-alert-screen.jpg';
export {default as backgroundXLUpsellAlertScreen} from './background-xl-upsell-alert-screen.jpg';
export {default as brainSignal} from './brain-signal.png';
export {default as chamberPerformance} from './chamber-performance.png';
export {default as colorWheel} from './color-wheel.png';
export {default as dataUnavailable} from './data-unavailable.png';
export {default as firstViewAdvancedMetrics} from './first-view-advanced-metrics.png';
export {default as flourishMoodlightInterstitialText} from './flourish-moodlight-interstitial-text.png';
export {default as account} from './icon-account.png';
export {default as add} from './icon-add.png';
export {default as arrowsOpposing} from './icon-arrows-opposing.png';
export {default as battery10} from './icon-battery-10.png';
export {default as battery25} from './icon-battery-25.png';
export {default as battery50} from './icon-battery-50.png';
export {default as battery75} from './icon-battery-75.png';
export {default as batteryCharging10} from './icon-battery-charging-10.png';
export {default as batteryCharging25} from './icon-battery-charging-25.png';
export {default as batteryCharging50} from './icon-battery-charging-50.png';
export {default as batteryCharging75} from './icon-battery-charging-75.png';
export {default as batteryChargingFull} from './icon-battery-charging-full.png';
export {default as batteryFull} from './icon-battery-full.png';
export {default as batteryUnknown} from './icon-battery-unknown.png';
export {default as book} from './icon-book.png';
export {default as brightness} from './icon-brightness.png';
export {default as calendar} from './icon-calendar.png';
export {default as check} from './icon-check.png';
export {default as chevronDown} from './icon-chevron-down.png';
export {default as chevronLeft} from './icon-chevron-left.png';
export {default as chevronRight} from './icon-chevron-right.png';
export {default as chevronUp} from './icon-chevron-up.png';
export {default as circular} from './icon-circular.png';
export {default as clock} from './icon-clock.png';
export {default as close} from './icon-close.png';
export {default as contact} from './icon-contact.png';
export {default as copy} from './icon-copy.png';
export {default as delete} from './icon-delete.png';
export {default as devicesSwitch} from './icon-devices-switch.png';
export {default as dictation} from './icon-dictation.png';
export {default as download} from './icon-download.png';
export {default as duplicate} from './icon-duplicate.png';
export {default as emptyAccount} from './icon-empty-account.png';
export {default as emptyCheckBox} from './icon-empty-check-box.png';
export {default as emptyCheckCircle} from './icon-empty-check-circle.png';
export {default as emptyCloseCircle} from './icon-empty-close-circle.png';
export {default as exclamation} from './icon-exclamation.png';
export {default as eyeSlash} from './icon-eye-slash.png';
export {default as eye} from './icon-eye.png';
export {default as filledCheckBox} from './icon-filled-check-box.png';
export {default as filledCircle} from './icon-filled-circle.png';
export {default as home} from './icon-home.png';
export {default as gear} from './icon-gear.png';
export {default as infoOutline} from './icon-info.png';
export {default as lightBulb} from './icon-light-bulb.png';
export {default as light} from './icon-light.png';
export {default as lightningBolt} from './icon-lightning-bolt.png';
export {default as lock} from './icon-lock.png';
export {default as logout} from './icon-logout.png';
export {default as mail} from './icon-mail.png';
export {default as menuTriple} from './icon-menu-triple.png';
export {default as more} from './icon-more.png';
export {default as noLightbulb} from './icon-no-lightbulb.png';
export {default as pencilOutlined} from './icon-pencil-outlined.png';
export {default as pencil} from './icon-pencil.png';
export {default as phone} from './icon-phone.png';
export {default as play} from './icon-play.png';
export {default as power} from './icon-power.png';
export {default as pulse} from './icon-pulse.png';
export {default as question} from './icon-question.png';
export {default as radiate} from './icon-radiate.png';
export {default as reorder} from './icon-reorder.png';
export {default as rocket} from './icon-rocket.png';
export {default as search} from './icon-search.png';
export {default as settings} from './icon-settings.png';
export {default as share} from './icon-share.png';
export {default as shareV2} from './icon-share2.png';
export {default as speechBubble} from './icon-speech-bubble.png';
export {default as spin} from './icon-spin.png';
export {default as stat} from './icon-stat.png';
export {default as stopwatchFilled} from './icon-stopwatch-filled.png';
export {default as stopwatch} from './icon-stopwatch.png';
export {default as telephonePlus} from './icon-telephone-plus.png';
export {default as thermometerFilled} from './icon-thermometer-filled.png';
export {default as thermometer} from './icon-thermometer.png';
export {default as thermometerDark} from './Thermometer.png';
export {default as trashcan} from './icon-trashcan.png';
export {default as unlock} from './icon-unlock.png';
export {default as logoClose2} from './logo-close-2.png';
export {default as logoClose} from './logo-close.png';
export {default as logoFar} from './logo-far.png';
export {default as logoInverted} from './logo-inverted.png';
export {default as photoLibrary} from './photo-library.png';
export {default as picker} from './picker.png';
export {default as pickerThinBorder} from './icon-picker-thin-border.png';
export {default as pickerThickBorder} from './icon-picker-thick-border.png';
export {default as pickerShadow} from './icon-picker-shadow.png';
export {default as removePicker} from './icon-remove-picker.png';
export {default as addPicker} from './icon-add-picker.png';
export {default as borealis} from './shader-borealis.png';
export {default as whiteCircleFilled} from './white-circle-filled.png';
export {default as whiteCircle} from './white-circle.png';
export {default as menuItemSeparator} from './menu-item-separator.png';
export {default as rainbow} from './rainbow.png';

// Standard Peak
export {default as peakBackgroundGlow} from './peak-background-glow.png';
export {default as peakLightsBaseLeft} from './peak-lights-base-left.png';
export {default as peakLightsBaseRight} from './peak-lights-base-right.png';
export {default as peakLightsGlassLeft} from './peak-lights-glass-left.png';
export {default as peakLightsGlassRight} from './peak-lights-glass-right.png';
export {default as peak} from './peak.png';

// LE1 Opal
export {default as backgroundGlitter} from './background-glitter.png';
export {default as le01Gem} from './le-01-gem.png';
export {default as multiColorOpalBackground2Color1} from './multi-color-opal-background-2-color-1.png';
export {default as multiColorOpalBackground2Color2} from './multi-color-opal-background-2-color-2.png';
export {default as multiColorOpalBackground3Color1} from './multi-color-opal-background-3-color-1.png';
export {default as multiColorOpalBackground3Color2} from './multi-color-opal-background-3-color-2.png';
export {default as multiColorOpalBackground3Color3} from './multi-color-opal-background-3-color-3.png';
export {default as multiColorOpalBackground4Color1} from './multi-color-opal-background-4-color-1.png';
export {default as multiColorOpalBackground4Color2} from './multi-color-opal-background-4-color-2.png';
export {default as multiColorOpalBackground4Color3} from './multi-color-opal-background-4-color-3.png';
export {default as multiColorOpalBackground4Color4} from './multi-color-opal-background-4-color-4.png';
export {default as multiColorOpalBackground5Color1} from './multi-color-opal-background-5-color-1.png';
export {default as multiColorOpalBackground5Color2} from './multi-color-opal-background-5-color-2.png';
export {default as multiColorOpalBackground5Color3} from './multi-color-opal-background-5-color-3.png';
export {default as multiColorOpalBackground5Color4} from './multi-color-opal-background-5-color-4.png';
export {default as multiColorOpalBackground5Color5} from './multi-color-opal-background-5-color-5.png';
export {default as multiColorOpalBackground6Color1} from './multi-color-opal-background-6-color-1.png';
export {default as multiColorOpalBackground6Color2} from './multi-color-opal-background-6-color-2.png';
export {default as multiColorOpalBackground6Color3} from './multi-color-opal-background-6-color-3.png';
export {default as multiColorOpalBackground6Color4} from './multi-color-opal-background-6-color-4.png';
export {default as multiColorOpalBackground6Color5} from './multi-color-opal-background-6-color-5.png';
export {default as multiColorOpalBackground6Color6} from './multi-color-opal-background-6-color-6.png';
export {default as peakOpalDarkScreen} from './peak-opal-dark-screen.png';
export {default as peakOpalLightsBaseFarLeft} from './peak-opal-lights-base-far-left.png';
export {default as peakOpalLightsBaseFarRight} from './peak-opal-lights-base-far-right.png';
export {default as peakOpalLightsBaseMidLeft} from './peak-opal-lights-base-mid-left.png';
export {default as peakOpalLightsBaseMidRight} from './peak-opal-lights-base-mid-right.png';
export {default as peakOpalLightsBaseRingFarLeft} from './peak-opal-lights-base-ring-far-left.png';
export {default as peakOpalLightsBaseRingFarRight} from './peak-opal-lights-base-ring-far-right.png';
export {default as peakOpalLightsBaseRingMidLeft} from './peak-opal-lights-base-ring-mid-left.png';
export {default as peakOpalLightsBaseRingMidRight} from './peak-opal-lights-base-ring-mid-right.png';
export {default as peakOpalLightsGlassFarLeft} from './peak-opal-lights-glass-far-left.png';
export {default as peakOpalLightsGlassFarRight} from './peak-opal-lights-glass-far-right.png';
export {default as peakOpalLightsGlassMidLeft} from './peak-opal-lights-glass-mid-left.png';
export {default as peakOpalLightsGlassMidRight} from './peak-opal-lights-glass-mid-right.png';
export {default as peakOpal} from './peak-opal.png';

// LE2 Indiglow
export {default as le02BackgroundHomeScreen} from './le-02-background-home-screen.png';
export {default as le02BackgroundIntro} from './le-02-background-intro.png';
export {default as le02BackgroundProfile} from './le-02-background-profile.png';
export {default as le02GemMask} from './le-02-gem-mask.png';
export {default as le02Gem} from './le-02-gem.png';
export {default as le02HeatProfileCardBackground} from './le-02-heat-profile-card-background.png';
export {default as le02HeatProfileListBackground} from './le-02-heat-profile-list-background.png';
export {default as le02LatticeIntroBottom} from './le-02-lattice-intro-bottom.png';
export {default as le02LatticeIntroModal} from './le-02-lattice-intro-modal.png';
export {default as le02PeakLightsBaseFarLeft} from './le-02-peak-lights-base-far-left.png';
export {default as le02PeakLightsBaseFarRight} from './le-02-peak-lights-base-far-right.png';
export {default as le02PeakLightsBaseMidLeft} from './le-02-peak-lights-base-mid-left.png';
export {default as le02PeakLightsBaseMidRight} from './le-02-peak-lights-base-mid-right.png';
export {default as le02PeakLightsBaseRingFarLeft} from './le-02-peak-lights-base-ring-far-left.png';
export {default as le02PeakLightsBaseRingFarRight} from './le-02-peak-lights-base-ring-far-right.png';
export {default as le02PeakLightsBaseRingMidLeft} from './le-02-peak-lights-base-ring-mid-left.png';
export {default as le02PeakLightsBaseRingMidRight} from './le-02-peak-lights-base-ring-mid-right.png';
export {default as le02PeakLightsGlassFarLeft} from './le-02-peak-lights-glass-far-left.png';
export {default as le02PeakLightsGlassFarRight} from './le-02-peak-lights-glass-far-right.png';
export {default as le02PeakLightsGlassMidLeft} from './le-02-peak-lights-glass-mid-left.png';
export {default as le02PeakLightsGlassMidRight} from './le-02-peak-lights-glass-mid-right.png';
export {default as le02Peak} from './le-02-peak.png';

// LE3 Guardian
export {default as le03BackgroundHomeScreen} from './le-03-background-home-screen.png';
export {default as le03BackgroundNamePeak} from './le-03-background-intro-name.png';
export {default as le03BackgroundIntro} from './le-03-background-intro.jpg';
export {default as le03BackgroundProfile} from './le-03-background-profile.png';
export {default as le03Gem} from './le-03-gem.png';
export {default as le03GroundLayer} from './le-03-ground-layer.png';
export {default as le03HeatProfileCardBackgroundEdit} from './le-03-heat-profile-card-background-edit.png';
export {default as le03HeatProfileCardBackground} from './le-03-heat-profile-card-background.png';
export {default as le03HeatProfileListBackground} from './le-03-heat-profile-list-background.jpeg';
export {default as le03HeatProfileListNavBackground} from './le-03-heat-profile-list-nav-background.png';
export {default as le03Peak} from './le-03-peak.png';
export {default as peakGuardianLightsBaseFarLeft} from './peak-guardian-lights-base-far-left.png';
export {default as peakGuardianLightsBaseFarRight} from './peak-guardian-lights-base-far-right.png';
export {default as peakGuardianLightsBaseMidLeft} from './peak-guardian-lights-base-mid-left.png';
export {default as peakGuardianLightsBaseMidRight} from './peak-guardian-lights-base-mid-right.png';
export {default as peakGuardianLightsBaseRingFarLeft} from './peak-guardian-lights-base-ring-far-left.png';
export {default as peakGuardianLightsBaseRingFarRight} from './peak-guardian-lights-base-ring-far-right.png';
export {default as peakGuardianLightsBaseRingMidLeft} from './peak-guardian-lights-base-ring-mid-left.png';
export {default as peakGuardianLightsBaseRingMidRight} from './peak-guardian-lights-base-ring-mid-right.png';
export {default as peakGuardianLightsGlassFarLeft} from './peak-guardian-lights-glass-far-left.png';
export {default as peakGuardianLightsGlassFarRight} from './peak-guardian-lights-glass-far-right.png';
export {default as peakGuardianLightsGlassMidLeft} from './peak-guardian-lights-glass-mid-left.png';
export {default as peakGuardianLightsGlassMidRight} from './peak-guardian-lights-glass-mid-right.png';

// LE4 Peak Pro Desert
/* Rest is same with Peach Peak */
export {default as le04Peak} from './peak-desert.png';

// LE5 Peak Pro Flourish
/* Rest is same with Peach Peak */
export {default as le05Peak} from './peak-flourish.png';

// LE5 Peak Pro Storm
export {default as peakStormGlass} from './peak-storm-glass.png';
export {default as peakStorm} from './peak-storm.png';
export {default as peakStormBase} from './peak-storm-base.png';
export {default as peakStormChamberRegular} from './peak-storm-chamber-regular.png';
export {default as peakStormChamberXl} from './peak-storm-chamber-xl.png';

export {default as glow} from './glow.png';
export {default as ring} from './ring.png';

export {default as peakShadow} from './peak-shadow.png';

export {default as heatProfileCoreCircleMask} from './heat-profile-core-circle-mask.png';

//Heat Glow Images
export {default as tintableHeatGlow1Color1} from './heat-glow-1-color-1.png';
export {default as tintableHeatGlow2Color1} from './heat-glow-2-color-1.png';
export {default as tintableHeatGlow2Color2} from './heat-glow-2-color-2.png';
export {default as tintableHeatGlow3Color1} from './heat-glow-3-color-1.png';
export {default as tintableHeatGlow3Color2} from './heat-glow-3-color-2.png';
export {default as tintableHeatGlow3Color3} from './heat-glow-3-color-3.png';
export {default as tintableHeatGlow4Color1} from './heat-glow-4-color-1.png';
export {default as tintableHeatGlow4Color2} from './heat-glow-4-color-2.png';
export {default as tintableHeatGlow4Color3} from './heat-glow-4-color-3.png';
export {default as tintableHeatGlow4Color4} from './heat-glow-4-color-4.png';
export {default as tintableHeatGlow5Color1} from './heat-glow-5-color-1.png';
export {default as tintableHeatGlow5Color2} from './heat-glow-5-color-2.png';
export {default as tintableHeatGlow5Color3} from './heat-glow-5-color-3.png';
export {default as tintableHeatGlow5Color4} from './heat-glow-5-color-4.png';
export {default as tintableHeatGlow5Color5} from './heat-glow-5-color-5.png';
export {default as tintableHeatGlow6Color1} from './heat-glow-6-color-1.png';
export {default as tintableHeatGlow6Color2} from './heat-glow-6-color-2.png';
export {default as tintableHeatGlow6Color3} from './heat-glow-6-color-3.png';
export {default as tintableHeatGlow6Color4} from './heat-glow-6-color-4.png';
export {default as tintableHeatGlow6Color5} from './heat-glow-6-color-5.png';
export {default as tintableHeatGlow6Color6} from './heat-glow-6-color-6.png';

// Cleaning Reminder
export {default as cleaningInstructionsThumbnail} from './cleaning-instructions-thumbnail.jpg';

//New home navigator icons
export {default as envelope} from './icon-envelope.png';
export {default as bluetoothBordered} from './icon-bluetooth-bordered.png';
export {default as questionMark} from './icon-question-mark.png';
export {default as openBook} from './icon-open-book.png';
export {default as phoneBordered} from './icon-phone-bordered.png';
export {default as settingsBordered} from './icon-settings-bordered.png';
export {default as shopBordered} from './icon-shop-bordered.png';
export {default as userBordered} from './icon-user-bordered.png';

// other sign in logo
export {default as appleLogo} from './icon-apple-logo.png';
export {default as googleLogo} from './icon-google-logo.png';

export {default as bluetoothStartPairing} from './bluetooth-start-pairing.jpg';

export {default as carouselAdvancedDab} from './background-carousel-advanced-dab.jpg';
export {default as carouselHeatProfile} from './background-carousel-heat-profile.jpg';
export {default as carouselMoodLight} from './background-carousel-mood-lights.jpg';

// Peach
export {default as peachBlackBackgroundHomeScreen} from './peach-black-background.png';
export {default as peachWhiteBackgroundHomeScreen} from './peach-white-background.png';

// Common Layers
export {default as peakBaseShadow} from './peak-base-shadow.png';

// Common LED Layers
export {default as peakPeachLightsGlassFarLeft} from './peak-peach-lights-glass-far-left.png';
export {default as peakPeachLightsGlassFarRight} from './peak-peach-lights-glass-far-right.png';
export {default as peakPeachLightsGlassMidLeft} from './peak-peach-lights-glass-mid-left.png';
export {default as peakPeachLightsGlassMidRight} from './peak-peach-lights-glass-mid-right.png';

export {default as legacyPeakPeachLightsGlassFarLeft} from './peak-peach-lights-glass-far-left-legacy.png';
export {default as legacyPeakPeachLightsGlassFarRight} from './peak-peach-lights-glass-far-right-legacy.png';
export {default as legacyPeakPeachLightsGlassMidLeft} from './peak-peach-lights-glass-mid-left-legacy.png';
export {default as legacyPeakPeachLightsGlassMidRight} from './peak-peach-lights-glass-mid-right-legacy.png';

// Peach Black Peak Layers
export {default as peakPeachBlackGlass} from './peak-peach-black-glass.png';
export {default as peakPeachBlack} from './peak-peach-black.png';
export {default as peakPeachBlackBase} from './peak-peach-black-base.png';
export {default as peakPeachBlackChamberRegular} from './peak-peach-black-chamber-regular.png';
export {default as peakPeachBlackChamberXl} from './peak-peach-black-chamber-xl.png';

// Peach Black LED Layers
export {default as peakPeachBlackLightsBaseLeft} from './peak-peach-black-lights-base-left.png';
export {default as peakPeachBlackLightsBaseRight} from './peak-peach-black-lights-base-right.png';

export {default as legacyPeakPeachBlackLightsBaseLeft} from './peak-peach-black-lights-base-left-legacy.png';
export {default as legacyPeakPeachBlackLightsBaseRight} from './peak-peach-black-lights-base-right-legacy.png';

// Peach White Peak Layers
export {default as peakPeachWhiteGlass} from './peak-peach-white-glass.png';
export {default as peakPeachWhite} from './peak-peach-white.png';
export {default as peakPeachWhiteBase} from './peak-peach-white-base.png';
export {default as peakPeachWhiteChamberRegular} from './peak-peach-white-chamber-regular.png';
export {default as peakPeachWhiteChamberXl} from './peak-peach-white-chamber-xl.png';

// Peach White LED Layers
export {default as peakPeachWhiteLightsBaseFarLeft} from './peak-peach-white-lights-base-far-left.png';
export {default as peakPeachWhiteLightsBaseFarRight} from './peak-peach-white-lights-base-far-right.png';
export {default as peakPeachWhiteLightsBaseMidLeft} from './peak-peach-white-lights-base-mid-left.png';
export {default as peakPeachWhiteLightsBaseMidRight} from './peak-peach-white-lights-base-mid-right.png';

export {default as peakPeachWhiteLightsRingFarLeft} from './peak-peach-white-lights-ring-far-left.png';
export {default as peakPeachWhiteLightsRingFarRight} from './peak-peach-white-lights-ring-far-right.png';
export {default as peakPeachWhiteLightsRingMidLeft} from './peak-peach-white-lights-ring-mid-left.png';
export {default as peakPeachWhiteLightsRingMidRight} from './peak-peach-white-lights-ring-mid-right.png';

export {default as peakWhitePeach} from './peak-peach-white.png';

export {default as skeletonLoading} from './background-loading-skeleton.png';

// Device Support
export {default as whiteBackArrow} from './icon-back-white.png';
export {default as learnMoreIcon} from './icon-learn-more.png';

// FAQ
export {default as faqAdd} from './icon-faq-add.png';
export {default as faqSubstact} from './icon-faq-substract.png';
export {default as searchBarIcon} from './icon-search-bar.png';
export {default as clearSearchIcon} from './icon-clear-search.png';
export {default as popularQuestionIcon} from './icon-popular-question.png';
export {default as searchNotFound} from './icon-search-not-found.png';
export {default as backToTop} from './icon-back-to-top.png';

// Referral program
export {default as addUser} from './icon-add-user.png';
export {default as backgroundRewardEarned} from './background-reward-earned.jpg';
export {default as backgroundReferAFriend} from './background-refer-a-friend.jpg';
export {default as backgroundMyRewards} from './background-my-rewards.jpg';
export {default as noRewards} from './no-rewards.png';
