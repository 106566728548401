import {HeaderBackButtonProps} from '@react-navigation/elements';
import {ProfileVersion, TemperatureUnit} from 'puffco-api-axios-client';
import React from 'react';
import {
  Dimensions,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from 'react-native';
import {useSelector} from 'react-redux';
import {ulid} from 'ulid';

import {rainbow} from '../../assets/images';
import {
  AppText,
  AutoAdjustScreen,
  BackButton,
  BottomSheetScrollable,
  MoodLightCircle,
  MoodLightList,
  StyledIcon,
  StyledSlider,
  TextSwitch,
  TextSwitchHandlePosition,
  useSpinner,
} from '../../components';
import {
  Constants,
  ErrorMessages,
  Messages,
  ProfileTitleStyle,
  Screens,
  Strings,
  appColors,
  checkMarkButtonStyle,
} from '../../constants';
import {useUpdateHeatProfiles} from '../../lib/api/HeatProfile.hooks';
import {changeHexColorValues} from '../../lib/changeHexColorValues';
import {checkStringBytes} from '../../lib/checkStringBytes';
import {convertHexToHsl} from '../../lib/convertHexToHsl';
import {convertHslToHex} from '../../lib/convertHslToHex';
import {useTemperature} from '../../lib/convertTemperature';
import {getTimeFormat} from '../../lib/getTimeFormat';
import {
  useAppDispatch,
  useBackPress,
  useChamberType,
  useGetUpdatedPeakMoodLights,
  useLantern,
  useMoodLight,
  useUpdateDabbingReduxValues,
} from '../../lib/hooks';
import {
  getActiveProfileMoodLights,
  profilesMatch,
  setProfileToColor,
  setProfileToMoodLight,
} from '../../lib/profileFunctions';
import {appSettingsSelector} from '../../lib/redux/appSettingsSlice';
import {connectedPeakSelector} from '../../lib/redux/bleSlice';
import {
  getMoodLightSelector,
  setPeakMoodLights,
} from '../../lib/redux/moodLightSlice';
import {
  activeProfilesSelector,
  addArchiveProfileFront,
  archiveProfilesSelector,
  updateActiveProfile,
  updateArchiveProfile,
} from '../../lib/redux/profilesSlice';
import {userSelector} from '../../lib/redux/userSlice';
import styled from '../../lib/styled';
import {
  MoodLight,
  Profile,
  isTHeatProfile,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {useDisconnectGuard} from '../../lib/useDisconnectGuard';
import {useSafeArea} from '../../lib/useSafeArea';
import {useTheme} from '../../lib/useTheme';
import {useThrottle} from '../../lib/useThrottle';
import {getVaporValue} from '../../lib/utilityFunctions';
import {Temperature} from '../../lib/utils/temperature';
import {HeatProfileEditNavigator} from '../../navigation/navigators/RootStackNavigator';
import {toHeatProfileList, toHome} from '../../navigation/navigators/util';
import {Alert} from '../../shims/alert';
import {SlideHandler} from '../components';
import {getVaporSnapValue} from './lib/getVaporSnapValue';

const {
  IS_WEB,
  SCREEN_HEIGHT: {GALAXY_S8},
  STD_HSL_VALUES: {saturation: stdS, lightness: stdL},
} = Constants;

const vaporList = ['STANDARD', 'HIGH', 'MAX', 'XL'];

const moodLightCircleSize = 16;
const sliderTitleMarginBottom = 10;

interface Props
  extends HeatProfileEditNavigator<typeof Screens.HeatProfileEdit> {}

export const HeatProfileEditScreen = ({route, navigation}: Props) => {
  useDisconnectGuard();
  const currentTheme = useTheme();
  const {navMenuTitleStyle, heatProfileEditScreenTheme} = currentTheme;
  const {navMenuTitleStyle: heatProfileMenuTitleStyle} =
    heatProfileEditScreenTheme;

  const {top} = useSafeArea();
  const dispatch = useAppDispatch();

  const {profileId, titleStyle, openMoodLight, headerTitle, showHeader} =
    route.params ?? {};

  const {width, height} = Dimensions.get('screen');
  const {height: winHeight} = Dimensions.get('window');

  const isSmall = (IS_WEB ? window.innerHeight : winHeight) <= GALAXY_S8;

  const nameMaxWidth = width * 0.8;

  const theme = useTheme();
  const user = useSelector(userSelector);
  const {tempPreference} = useSelector(appSettingsSelector);
  const peak = useSelector(connectedPeakSelector);
  const archives: Profile[] = useSelector(archiveProfilesSelector);
  const actives: Profile[] = useSelector(activeProfilesSelector);
  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();
  const updateDabbingReduxValues = useUpdateDabbingReduxValues();

  const {accessMoodLight, canUseMoodLighting, hasSeenEpilepsyWarning} =
    useMoodLight();

  const canToggleMoodLights =
    canUseMoodLighting && !!user && hasSeenEpilepsyWarning;

  const currentProfile = React.useMemo(
    () =>
      profileId
        ? actives.find(p => p.id === profileId) ??
          archives.find(p => p.id === profileId)
        : undefined,
    [actives, archives, profileId],
  );

  const isActive = React.useMemo(
    () => !!profileId && actives.some(p => p.id === profileId),
    [profileId, actives],
  );

  const isSaving = React.useRef(false);
  const nameRef = React.useRef<TextInput>(null);

  const [shouldOpen, setShouldOpen] = React.useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = React.useState<boolean>(false);

  const minTemp = useTemperature(
    Constants.TEMPERATURE_MIN_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const maxTemp = useTemperature(
    Constants.TEMPERATURE_MAX_FAHRENHEIT,
    TemperatureUnit.Fahrenheit,
  );

  const [{error, value, loading: updateLoading}, updateApiArchives] =
    useUpdateHeatProfiles();

  const profileParam: Profile = React.useMemo(() => {
    const profile: Profile = currentProfile ?? {
      ...(canUseMoodLighting
        ? {
            version: ProfileVersion.T,
            isMoodLight: false,
            vaporSetting: 0,
          }
        : {version: ProfileVersion.PreT}),
      modified: new Date().getTime(),
      id: ulid(),
      name: 'NEW PROFILE',
      color: appColors.defaultColor,
      temperature: minTemp,
      units: tempPreference || TemperatureUnit.Fahrenheit,
      duration: Constants.DURATION_MIN,
      order: 0,
      userId: user?.id,
    };

    return {
      ...profile,
      temperature: Temperature.convert(
        profile?.temperature ?? Constants.TEMPERATURE_MIN_FAHRENHEIT,
        {
          from: profile?.units ?? TemperatureUnit.Fahrenheit,
          to: tempPreference,
        },
      ),
    };
  }, [canUseMoodLighting, currentProfile, minTemp, tempPreference, user]);

  const [profile, setProfile] = React.useState<Profile | undefined>(
    profileParam,
  ); // keep in preference units until saved

  const profileColor = React.useMemo(
    () =>
      !profile || isTHeatProfileMoodLight(profile)
        ? appColors.defaultColor
        : profile.color,
    [profile],
  );

  const [moodLight, setMoodLight] = React.useState<MoodLight | undefined>(
    !!profile && isTHeatProfileMoodLight(profile)
      ? getMoodLight(profile.moodLightId)
      : undefined,
  );

  const isMoodLight = !!profile && isTHeatProfileMoodLight(profile);

  const getInitialHandlePosition = React.useCallback(() => {
    return moodLight
      ? TextSwitchHandlePosition.RIGHT
      : TextSwitchHandlePosition.LEFT;
  }, [moodLight]);

  const [switchPosition, setSwitchPosition] = React.useState(
    getInitialHandlePosition(),
  );

  const inputTextMultiplier = 18;
  const inputWidth = Math.min(
    profile?.name ? (profile?.name?.length + 1) * inputTextMultiplier : 0,
    nameMaxWidth,
  );

  const hasNameError =
    !!profile &&
    (profile.name.trim().length === 0 ||
      !checkStringBytes(profile.name, 'profile'));

  const {isVaporEnabled, isMeetAC, is3dChamber, isXLChamber} = useChamberType();

  const availableVaporList =
    profileParam && profileParam.version === ProfileVersion.T
      ? isXLChamber
        ? vaporList
        : vaporList.slice(0, vaporList.length - 1)
      : undefined;

  const handleGetVaporValue = (v: number) => {
    return getVaporSnapValue(
      v / 10,
      isXLChamber ? [0, 0.5, 1, 1.5] : [0, 0.5, 1],
    );
  };

  useSpinner({isVisible: updateLoading});

  useBackPress(
    React.useCallback(() => {
      if (bottomSheetOpen) {
        setBottomSheetOpen(false);
      } else {
        navigation.goBack();
      }
      return true;
    }, [bottomSheetOpen, navigation]),
  );

  React.useEffect(() => {
    if (!profile) {
      let profileToSet = profileParam;
      if (isTHeatProfile(profileParam)) {
        if (isTHeatProfileMoodLight(profileParam)) {
          const {moodLightId} = profileParam;
          const profileMoodLight = getMoodLight(moodLightId);
          setMoodLight(profileMoodLight);
          profileToSet = setProfileToMoodLight(profileParam, moodLightId);
        } else {
          setMoodLight(undefined);
          profileToSet = setProfileToColor(profileParam, profileParam.color);
        }
      }
      setProfile(profileToSet);
    }
  }, [getMoodLight, profileParam]);

  React.useEffect(() => {
    if (openMoodLight) {
      setBottomSheetOpen(true);
      navigation.setParams({openMoodLight: undefined});
    }
  }, [openMoodLight, navigation]);

  const setTemperature = React.useCallback(
    (temp: number) => {
      setProfile(profile =>
        profile ? {...profile, temperature: temp} : undefined,
      );
    },
    [setProfile],
  );

  const setDuration = React.useCallback(
    (duration: number) =>
      setProfile(profile => (profile ? {...profile, duration} : undefined)),
    [setProfile],
  );

  const setVaporMood = React.useCallback(
    (vaporSetting: number) =>
      setProfile(profile => (profile ? {...profile, vaporSetting} : undefined)),
    [setProfile],
  );

  const setColor = React.useCallback(
    (color: number) => {
      setProfile(profile =>
        profile
          ? {
              ...profile,
              color: convertHslToHex(color, stdS, stdL, true),
            }
          : undefined,
      );
    },
    [setProfile],
  );

  const throttledSetColor = useThrottle(setColor, 1000);

  React.useEffect(() => {
    if (bottomSheetOpen) {
      navigation.setParams({showHeader: false});
    } else if (!moodLight) {
      navigation.setParams({showHeader: true});
    } else if (moodLight && !bottomSheetOpen) {
      navigation.setParams({showHeader: true});
    }
  }, [bottomSheetOpen, moodLight, navigation]);

  React.useEffect(() => {
    navigation.setParams({
      titleStyle: {
        ...(heatProfileMenuTitleStyle ?? navMenuTitleStyle),
        color: appColors.white,
      },
      headerTitle: currentProfile?.id ? 'EDIT' : undefined,
    });
  }, [heatProfileMenuTitleStyle, navMenuTitleStyle, currentProfile?.id]);

  const displayColors =
    isMoodLight && moodLight
      ? moodLight.colors
      : [changeHexColorValues(profileColor, stdS, stdL, true)];

  useLantern(
    switchPosition === TextSwitchHandlePosition.RIGHT
      ? moodLight
      : displayColors[0],
  );

  const navigateToHeatProfileList = () => {
    navigation.navigate(...toHeatProfileList.encode());

    return;
  };

  const saveHeatProfile = React.useCallback(() => {
    if (profile) {
      const temperature = Temperature.convert(profile.temperature, {
        from: tempPreference,
        to: profile.units,
      });

      let save: Profile = {
        ...profile,
        temperature,
        ...(profile.version === ProfileVersion.T && {
          modified: new Date().getTime(),
        }),
        ...(moodLight && switchPosition === TextSwitchHandlePosition.RIGHT
          ? {
              isMoodLight: true,
              moodLightId: moodLight.id,
              color: appColors.defaultColor,
            }
          : {moodLightId: null, isMoodLight: false, color: profileColor}),
      };

      if (!isSaving.current && !hasNameError) {
        isSaving.current = true;
        if (isActive) {
          if (save.version === ProfileVersion.T) {
            const matchingArchive = archives.find(archive =>
              profilesMatch(archive, save),
            );
            if (matchingArchive && user) {
              // Keep matching active and archive profiles in sync
              const saveWithUserId = {
                ...save,
                userId: matchingArchive.userId ?? user.id,
              };
              const updatedArchive = {
                ...saveWithUserId,
                order: matchingArchive.order,
              };
              save = saveWithUserId;
              updateApiArchives([updatedArchive]);
              dispatch(updateArchiveProfile(updatedArchive));
            }
          }
          peak?.writeHeatProfiles(
            [save],
            getActiveProfileMoodLights([save], getMoodLight),
          );
          dispatch(
            setPeakMoodLights(
              getUpdatedPeakMoodLights({
                actives: actives.map(active =>
                  active.order === save.order ? save : active,
                ),
              }),
            ),
          );
          dispatch(updateActiveProfile(save));
          updateDabbingReduxValues(save);

          if (navigation.canGoBack()) navigation.goBack();
          else navigation.replace(...toHome);
        } else if (user) {
          const moodlightsData =
            switchPosition === TextSwitchHandlePosition.RIGHT
              ? moodLight
              : undefined;

          let moodLightWithId = undefined;
          if (moodlightsData) {
            moodLightWithId = {...moodlightsData, userId: user.id};
          }

          updateApiArchives([
            {...save, userId: user.id, moodLight: moodLightWithId},
          ]);
        }
      }
    }
  }, [
    peak,
    actives,
    archives,
    getMoodLight,
    getUpdatedPeakMoodLights,
    hasNameError,
    isActive,
    moodLight,
    profile,
    profileColor,
    tempPreference,
    updateApiArchives,
    updateDabbingReduxValues,
    user,
    switchPosition,
  ]);

  React.useEffect(() => {
    const options = showHeader
      ? {
          headerLeft: ({onPress}: HeaderBackButtonProps) => (
            <BackButton
              {...{onPress}}
              iconStyle={{tintColor: titleStyle?.color}}
            />
          ),
          headerRight: () => (
            <StyledIcon
              {...checkMarkButtonStyle}
              onPress={saveHeatProfile}
              iconStyle={{tintColor: titleStyle?.color}}
            />
          ),
          headerTitle: () => (
            <HeaderTitle style={titleStyle}>{headerTitle}</HeaderTitle>
          ),
          headerShown: true,
        }
      : {headerShown: false};

    navigation.setOptions(options);
  }, [saveHeatProfile, titleStyle, navigation, headerTitle, showHeader]);

  React.useEffect(() => {
    if (profile) {
      const temperature = Temperature.convert(profile?.temperature ?? 0, {
        from: tempPreference,
        to: profile?.units ?? TemperatureUnit.Celsius,
      });

      if (!!value && !isActive) {
        const save: Profile = {
          ...profile,
          temperature,
          ...(moodLight && switchPosition === TextSwitchHandlePosition.RIGHT
            ? {
                isMoodLight: true,
                moodLightId: moodLight.id,
              }
            : {isMoodLight: false, color: profileColor}),
        };
        if (!currentProfile?.id) {
          dispatch(addArchiveProfileFront(save));
          navigateToHeatProfileList();
        } else {
          navigation.goBack();
        }
        dispatch(updateArchiveProfile(save));
      } else if (error) {
        Alert.alert('Error', ErrorMessages.NETWORK_ERROR);
        if (!currentProfile?.id) {
          navigateToHeatProfileList();

          return;
        }

        navigation.goBack();
      }
    }
  }, [currentProfile?.id, value, error, switchPosition]);

  const onDisabledPress = React.useCallback(() => {
    accessMoodLight({profileId: profile?.id});
  }, [accessMoodLight, profile]);

  const timeFormat = (val: number) =>
    `${Math.floor(val / 60)}:${`${val % 60}`.padStart(2, '0')}`;

  const getVaporSubText = React.useCallback(() => {
    let vaporSubString: string | undefined = undefined;
    if (!isMeetAC && !is3dChamber) {
      vaporSubString =
        Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER_AND_FIRMWARE;
    } else if (!is3dChamber) {
      vaporSubString = Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_CHAMBER;
    } else {
      vaporSubString = Messages.HEAT_PROFILE_EDIT_VAPOR_UNSUPPORTED_FIRMWARE;
    }
    return vaporSubString;
  }, [isMeetAC, is3dChamber]);

  React.useEffect(() => {
    if (canUseMoodLighting) {
      setProfile(v =>
        !!v && isTHeatProfile(v)
          ? switchPosition === TextSwitchHandlePosition.LEFT && !!moodLight
            ? setProfileToColor(v, displayColors[0])
            : v
          : undefined,
      );
    }
  }, [canUseMoodLighting, switchPosition]);

  // make sure initial position match TextSwitch
  React.useEffect(() => {
    setSwitchPosition(getInitialHandlePosition());
  }, [getInitialHandlePosition]);

  return (
    <MainContentContainer>
      <ScrollableContainer
        contentContainerStyle={{
          height: Constants.IS_WEB ? 0 : undefined,
        }}>
        {!!profile && (
          <ContentContainer
            style={{
              width: '100%',
              alignItems: 'center',
            }}>
            <HeaderContainer style={{marginTop: IS_WEB ? top + 20 : 10}}>
              <TitleContainer
                style={{
                  height: '100%',
                  justifyContent: 'center',
                }}>
                <TextInput
                  allowFontScaling={false}
                  autoCapitalize="characters"
                  ref={nameRef}
                  value={profile?.name || ''}
                  onChangeText={(text: string) => {
                    setProfile(prof =>
                      prof
                        ? {
                            ...prof,
                            name: text?.toUpperCase() || '',
                          }
                        : undefined,
                    );
                  }}
                  keyboardType={
                    Constants.IS_NATIVE_ANDROID ? 'visible-password' : 'default'
                  }
                  maxLength={Constants.PROFILE_NAME_MAX_LENGTH}
                  onBlur={() => {
                    if (shouldOpen) {
                      nameRef?.current?.focus();
                      setShouldOpen(false);
                    }
                    setProfile(prof =>
                      prof
                        ? {
                            ...prof,
                            name: prof?.name?.trim() || '',
                          }
                        : undefined,
                    );
                  }}
                  style={{
                    ...ProfileTitleStyle,
                    fontSize: 24,
                    fontWeight: '500',
                    letterSpacing: 0.18,
                    color: appColors.white,
                    width: inputWidth,
                    maxWidth: '80%',
                    textTransform: 'uppercase',
                  }}
                />

                <StyledIcon
                  style={{width: 10}}
                  iconStyle={{tintColor: appColors.white}}
                  size={12}
                  name="pencil"
                  onPress={() => {
                    nameRef.current?.focus();
                    Constants.IS_WEB && setShouldOpen(true);
                  }}
                />
              </TitleContainer>

              {hasNameError && (
                <ErrorContainer>
                  <ErrorText>
                    {profile?.name?.trim().length === 0
                      ? ErrorMessages.PROFILE_NAME_EMPTY
                      : ErrorMessages.PROFILE_NAME_LONG}
                  </ErrorText>
                </ErrorContainer>
              )}
            </HeaderContainer>

            <MainSliderContainer>
              <SlideHandler<number>
                initialValue={Math.round(profile?.temperature)}
                update={setTemperature}>
                {(temp, setTemp) => (
                  <StyledSlider
                    paddingBottom={isSmall ? 8 : 40}
                    title="TEMPERATURE"
                    message={`${temp}°`}
                    fontColor={appColors.white}
                    bottomFontColor={appColors.white50}
                    containerWidth="100%"
                    paddingHorizontal={7}
                    step={1}
                    maximumValue={maxTemp}
                    minimumValue={minTemp}
                    numberFormat={val => `${val}°`}
                    maximumTrackTintColor={appColors.transluscentLightGray}
                    minimumTrackTintColor={appColors.white}
                    thumbTintColor={appColors.white}
                    color={appColors.white50}
                    value={temp}
                    onValueChange={setTemp}
                    onSlidingComplete={setTemp}
                    titleMarginBottom={sliderTitleMarginBottom}
                  />
                )}
              </SlideHandler>

              <SlideHandler<number>
                initialValue={profile?.duration}
                update={setDuration}>
                {(duration, setDur) => (
                  <StyledSlider
                    paddingBottom={isSmall ? 8 : 40}
                    title="DURATION"
                    message={getTimeFormat(duration, {
                      padMinutes: false,
                      padSeconds: true,
                    })}
                    fontColor={appColors.white}
                    bottomFontColor={appColors.white50}
                    containerWidth="100%"
                    step={1}
                    paddingHorizontal={7}
                    maximumValue={Constants.DURATION_MAX}
                    minimumValue={Constants.DURATION_MIN}
                    numberFormat={timeFormat}
                    maximumTrackTintColor={appColors.transluscentLightGray}
                    minimumTrackTintColor={appColors.white}
                    thumbTintColor={appColors.white}
                    color={appColors.white}
                    value={duration}
                    onValueChange={setDur}
                    onSlidingComplete={setDur}
                    titleMarginBottom={sliderTitleMarginBottom}
                  />
                )}
              </SlideHandler>

              <SlideHandler<number>
                initialValue={getVaporValue(profile) || 0}
                update={setVaporMood}>
                {(vapor, setVapor) => {
                  const maxValue =
                    (availableVaporList
                      ? availableVaporList.length - 1
                      : vaporList.length - 1) / 2;

                  if (vapor > maxValue) setVapor(maxValue);

                  return (
                    <StyledSlider
                      paddingBottom={0}
                      title="VAPOR CONTROL"
                      titleIcon={!isVaporEnabled ? 'lock' : undefined}
                      message={`${vaporList[vapor * 2]}`}
                      fontColor={appColors.white}
                      bottomFontColor={appColors.white50}
                      containerWidth="100%"
                      paddingHorizontal={7}
                      step={1}
                      maximumValue={
                        (isXLChamber ? Constants.XL_MAX : Constants.VAPOR_MAX) *
                        10
                      }
                      minimumValue={0}
                      numberFormat={val => vaporList[val * 2]}
                      maximumTrackTintColor={appColors.transluscentLightGray}
                      minimumTrackTintColor={appColors.white}
                      thumbTintColor={appColors.white}
                      color={appColors.white}
                      value={vapor * 10}
                      onValueChange={(v: number) =>
                        setVapor(handleGetVaporValue(v))
                      }
                      onSlidingComplete={(v: number) => {
                        setVapor(handleGetVaporValue(v));
                      }}
                      onAutoSlide={(v: number) =>
                        (handleGetVaporValue(v) * 100) / (isXLChamber ? 1.5 : 1)
                      }
                      tickers={availableVaporList}
                      nTickers={
                        availableVaporList
                          ? availableVaporList.length
                          : undefined
                      }
                      enabled={isVaporEnabled}
                      titleMarginBottom={sliderTitleMarginBottom}
                    />
                  );
                }}
              </SlideHandler>

              <VaporSubTextContainer
                style={{
                  marginTop: isSmall ? 5 : 10,
                  marginBottom: isSmall ? 8 : 16,
                }}>
                <StyledIcon
                  size={15}
                  name="infoOutline"
                  color={!isVaporEnabled ? appColors.white50 : appColors.black}
                />

                {!isVaporEnabled && (
                  <VaporSubText>{getVaporSubText()}</VaporSubText>
                )}
              </VaporSubTextContainer>

              <TextSwitch
                initialHandlePosition={getInitialHandlePosition()}
                leftText="COLOR"
                rightText="MOOD"
                disabledPosition={
                  !canToggleMoodLights
                    ? TextSwitchHandlePosition.RIGHT
                    : undefined
                }
                onDisabledPress={onDisabledPress}
                onToggle={(position: TextSwitchHandlePosition) =>
                  setSwitchPosition(position)
                }
              />

              <TextSwitchBottomContainer>
                {switchPosition === 0 ? (
                  <ColorSliderContainer>
                    <SlideHandler<string[]>
                      initialValue={displayColors}
                      update={v => throttledSetColor(convertHexToHsl(v[0]))}
                      slideAutoUpdate={true}>
                      {(colorTemp, setColorTemp) => (
                        <StyledSlider
                          trackImage={rainbow}
                          maximumValue={0.999}
                          minimumValue={0.001}
                          thumbTintColor={appColors.white}
                          color={appColors.white}
                          bottomFontColor={appColors.white50}
                          containerWidth="100%"
                          paddingHorizontal={7}
                          value={convertHexToHsl(colorTemp[0])}
                          onValueChange={(v: any) =>
                            setColorTemp([convertHslToHex(v, stdS, stdL, true)])
                          }
                          onSlidingComplete={(v: any) =>
                            setColorTemp([convertHslToHex(v, stdS, stdL, true)])
                          }
                          noHeader={true}
                          tickers={[]}
                        />
                      )}
                    </SlideHandler>
                  </ColorSliderContainer>
                ) : (
                  <MoodLightButtonContainer
                    onPress={() => {
                      if (!canToggleMoodLights) {
                        onDisabledPress();
                      } else {
                        setBottomSheetOpen(true);
                      }
                    }}>
                    <MoodLightButtonText>
                      {Messages.HEAT_PROFILE_EDIT_MOOD_LIGHT_BTN}
                    </MoodLightButtonText>

                    <MoodLightButtonRight>
                      {moodLight && (
                        <>
                          <GradientCircle style={{marginRight: 10}}>
                            <MoodLightCircle
                              moodLight={moodLight}
                              theme={theme}
                              size={moodLightCircleSize}
                            />
                          </GradientCircle>

                          <MoodLightButtonRightText numberOfLines={1}>
                            {moodLight.name}
                          </MoodLightButtonRightText>
                        </>
                      )}

                      <StyledIcon
                        iconStyle={{tintColor: appColors.white}}
                        size={16}
                        name="chevronRight"
                      />
                    </MoodLightButtonRight>
                  </MoodLightButtonContainer>
                )}
              </TextSwitchBottomContainer>
            </MainSliderContainer>
          </ContentContainer>
        )}
      </ScrollableContainer>

      {bottomSheetOpen && (
        <BottomSheetScrollable
          addButtonAction={() =>
            navigation.navigate(Screens.MoodLightController)
          }
          headerOnlyVisibleHeight={0}
          onOpenChange={isOpen => setBottomSheetOpen(isOpen)}
          onMinimizedPress={(enabled: boolean) => !enabled && onDisabledPress()}
          isEnabled={canToggleMoodLights}
          isOpen={bottomSheetOpen}
          innerComponent={() => (
            <MoodLightList
              setActiveMoodLightId={moodLightId =>
                moodLightId
                  ? setMoodLight(getMoodLight(moodLightId))
                  : setMoodLight(undefined)
              }
              currentActiveId={moodLight?.id}
              shouldShowPeakMoodLights={isActive}
            />
          )}
          titleHeader={
            bottomSheetOpen ? Strings.MOOD_LIGHTS : Strings.MOOD_LIGHTING
          }
          titleSubheader={moodLight?.name ?? ''}
          screenHeight={height}
        />
      )}
    </MainContentContainer>
  );
};

// Header
const HeaderContainer = styled(View)({
  maxWidth: '80%',
  height: 200,
  flexDirection: 'column',
  alignItems: 'center',
});

const VaporSubTextContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: -10,
  width: '90%',
});

const MainContentContainer = styled(View)({
  width: '100%',
  flex: 1,
  backgroundColor: appColors.black,
});

const ScrollableContainer = styled(ScrollView)({
  flex: 1,
});

const ContentContainer = styled(AutoAdjustScreen)({
  alignItems: 'center',
  marginBottom: 40,
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorText = styled(Text)({
  color: appColors.errorColor,
  fontSize: 14,
  fontFamily: 'BigShouldersDisplay-Medium',
  fontWeight: '400',
});

const ErrorContainer = styled(View)({
  height: 30,
});

// Footer
const MainSliderContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
});

const TextSwitchBottomContainer = styled(View)({
  height: 40, // Matches slider height
  marginTop: 24,
  marginBottom: 40,
});

const VaporSubText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 12,
  color: appColors.white,
});

const ColorSliderContainer = styled(View)({
  marginTop: 10,
});

const MoodLightButtonText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 16,
  color: appColors.white,
});

const MoodLightButtonContainer = styled(Pressable)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 12,
  backgroundColor: appColors.gray19,
  width: '100%',
  height: 56,
  paddingRight: 5,
  paddingLeft: 24,
});

const MoodLightButtonRight = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const MoodLightButtonRightText = styled(Text)({
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  color: appColors.white50,
  fontSize: 16,
});

const GradientCircle = styled(View)({
  height: moodLightCircleSize,
  width: moodLightCircleSize,
  borderRadius: 1000,
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
});

const HeaderTitle = styled(AppText)({
  fontFamily: 'Roboto-Bold',
  fontSize: 14,
  marginTop: 10,
});
