import React from 'react';
import {useSelector} from 'react-redux';

import {Interstitial, Modal} from '../../components';
import {Screens} from '../../constants';
import {MarketOptIn, SMSMarketOptIn} from '../../navigation/components';
import {
  MetricsInterstitial,
  XLProductReleaseInterstitial,
  XLUnlockedInterstitial,
  XLUpsellInterstitial,
} from '../../screens';
import {v3ChamberInterstitial} from '../../screens';
import {flourishMoodlightInterstitial} from '../../screens/main/FlourishMoodlightInterstitial';
import {InterstitialManager, InterstitialScreen} from '../InterstitialManager';
import NavigationService from '../NavigationService';
import {geolocationApi} from '../api/apis';
import {stateSelector} from '../redux';
import {updateAppFlags} from '../redux/appFlagsSlice';
import {currentDeviceSelector} from '../redux/bleSlice';
import {viewedInterstitialIdsSelector} from '../redux/interstitialSlice';
import {addId} from '../redux/interstitialSlice';
import {useAppDispatch} from './useAppDispatch';
import {useWatchDevice} from './useWatchDevice';

// Interstitials
const interstitials: InterstitialScreen[] = [
  flourishMoodlightInterstitial,
  v3ChamberInterstitial,
  XLProductReleaseInterstitial,
  XLUpsellInterstitial,
  XLUnlockedInterstitial,
  MetricsInterstitial,
];

export const useInterstitial = (initial = true) => {
  const [shouldRun, setShouldRun] = React.useState(initial);
  const [seenScreens, setSeenScreens] = React.useState<string[]>([]);
  const device = useSelector(currentDeviceSelector);

  const state = useSelector(stateSelector);
  const dispatch = useAppDispatch();

  const currentActiveRouteName = NavigationService.getCurrentRouteName();

  const geolocationPromise = React.useMemo(async () => {
    const res = await geolocationApi.getCountry().catch(() => undefined);

    if (!res) return null;

    return res.data.ipcountry;
  }, []);

  const MarketOptInInterstitial: InterstitialScreen = {
    conditionMet: ({state}) =>
      state.user &&
      state.user.marketOptIn === null &&
      !state.appFlags.hasSeenMarketOptIn,
    modalComponent: dispatch => ({
      element: <MarketOptIn />,
      onClose: () => dispatch(updateAppFlags({hasSeenMarketOptIn: true})),
    }),
    startDate: new Date('2023-01-01'),
  };

  const SMSOptInInterstitial: InterstitialScreen = {
    // SMS Market Opt In
    conditionMet: ({state, data}) =>
      data &&
      data === 'US' &&
      !state.user &&
      !state.appFlags.hasSeenSMSMarketOptIn &&
      state.appFlags.launchCount >= 3,
    modalComponent: () => ({
      element: <SMSMarketOptIn />,
      onClose: () => {
        dispatch(updateAppFlags({hasSeenSMSMarketOptIn: true}));
      },
    }),
    getData: async () => await geolocationPromise,
    startDate: new Date('2023-01-01'),
  };

  const ModalOptInInterstitials = [
    MarketOptInInterstitial,
    SMSOptInInterstitial,
  ];

  const allInterstitials = interstitials.concat(ModalOptInInterstitials);

  // Sort the interstitials by priority with 0 is lowest priority
  const sortedInterstitials = allInterstitials.sort((a, b) =>
    (a.startDate?.getTime() || 0) > (b.startDate?.getTime() || 0) ? -1 : 1,
  );

  const triggerScreens = interstitials.reduce(
    (acc: string[], {triggerScreen}) =>
      triggerScreen
        ? typeof triggerScreen === 'string'
          ? [...acc, triggerScreen]
          : [...acc, ...triggerScreen]
        : acc,
    [Screens.Home],
  );

  const chamberType = useWatchDevice('chamberType');

  const retriggerParams = {
    chamberType,
    deviceId: device?.id,
  };

  React.useEffect(() => {
    manager.resetIndex();
    setShouldRun(true);
  }, Object.values(retriggerParams));

  const manager = InterstitialManager.getInstance(
    [
      {
        getData: () => InterstitialManager.interstitialPromise,
        conditionMet: ({state, data}) => {
          const interstitialIds = viewedInterstitialIdsSelector(state);
          return data && !interstitialIds.includes(data.id);
        },
        modalComponent: (dispatch, data) => ({
          element: <Interstitial interstitial={data} />,
          onClose: () => dispatch(addId(data.id)),
        }),
      },
      ...sortedInterstitials,
    ],
    retriggerParams,
  );

  // Retrigger the manager if current screen is unseen and in the trigger screen list
  const alreadySeen = seenScreens.includes(currentActiveRouteName);

  React.useEffect(() => {
    if (triggerScreens.includes(currentActiveRouteName) && !alreadySeen) {
      setSeenScreens(prev => [...prev, currentActiveRouteName]);
      manager.resetIndex();
      setShouldRun(true);
    }
  }, [currentActiveRouteName, alreadySeen, triggerScreens, manager]);

  const helper = React.useCallback(async () => {
    const currentScreen = await manager.getCurrentScreen(
      state,
      dispatch,
      retriggerParams,
    );

    if (!currentScreen) return;

    if (currentScreen.modal) return Modal.display(currentScreen.modal);

    if (!currentScreen?.screen) return;

    const route = NavigationService.instance()?.getCurrentRoute();

    NavigationService.instance()?.navigate(
      currentScreen.screen as any,
      route?.params,
    );
  }, [manager, retriggerParams, state]);

  React.useEffect(() => {
    if (!shouldRun) return;

    if (shouldRun) {
      helper().then(() => {
        setShouldRun(false);
      });
    }
  }, [shouldRun, helper]);

  return {setShouldRun, hasNextScreen: manager.hasNextScreen()};
};
