import {MoodLightCategory} from 'puffco-api-axios-client';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAsyncFn} from 'react-use';

import {
  Analytics,
  CustomCategory,
  CustomEventAction,
  CustomLabel,
} from '../../analytics/Analytics';
import {Alerts, Constants, appColors, interpolate} from '../../constants';
import {Alert} from '../../shims/alert';
import {useAppDispatch, useGetUpdatedPeakMoodLights} from '../hooks';
import {setProfileToWhite} from '../profileFunctions';
import {
  connectedPeakSelector,
  currentDeviceIdSelector,
  currentDeviceSettingsSelector,
  updateDeviceSettings,
} from '../redux/bleSlice';
import {
  accountMoodLightsSelector,
  deleteAccountMoodLight,
  lanternMoodLightSelector,
  peakMoodLightsSelector,
  setExclusiveMoodLights,
  setLanternMoodLightId,
  setPeakMoodLights,
  updateUsernameCache,
  upsertAccountMoodLight,
  usernameCacheSelector,
} from '../redux/moodLightSlice';
import {
  activeProfilesSelector,
  archiveProfilesSelector,
  tempProfileSelector,
  updateActiveProfile,
  updateArchiveProfiles,
  updateTempProfile,
} from '../redux/profilesSlice';
import {userSelector} from '../redux/userSlice';
import {
  CustomMoodLight,
  Dictionary,
  ExclusiveMoodLight,
  Profile,
  ProfileT,
  User,
  isCustomMoodLight,
  isTHeatProfileMoodLight,
} from '../types';
import {
  convertAnimArrayToNumArray,
  convertHexArrayToNumArray,
  convertHexStringToNumArray,
} from '../utilityFunctions';
import {moodlightApi, userApi} from './apis';
import {AsyncWrapperOptions, withAsyncWrapper} from './asyncWrapper';

export function useGetMoodLights() {
  return useAsyncFn(async () => {
    const response = await userApi.getMoodLights({id: 'me'});

    return response.data.map(
      light =>
        ({
          ...light,
          led3Meta: light.led3Meta ?? {
            userColors: [...light.colors],
            tempoFrac: light.tempo,
          },
        }) as unknown as CustomMoodLight,
    );
  }, []);
}

export type SaveMoodLightDto = Omit<CustomMoodLight, 'modified'> & {
  modified: Date;
  userId: number;
};

export const saveMoodlight = async (
  moodLight: CustomMoodLight,
  user?: User,
) => {
  if (!user) return;

  const {data} = await moodlightApi.updateMoodLight({
    moodLightUpdateDto: [
      {
        ...moodLight,
        category: moodLight.category,
        modified: new Date().toISOString(),
        userId: user.id,
      },
    ],
  });

  const response: CustomMoodLight = {
    ...data[0],
    category: data[0].category,
    modified: new Date(data[0].modified).getTime(),
  };

  return response;
};

export function useSaveMoodLight({
  onSuccess,
  onError,
}: AsyncWrapperOptions<CustomMoodLight>) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return useAsyncFn(
    withAsyncWrapper(
      async (moodLight: CustomMoodLight) => {
        if (!user) return;

        const {data} = await moodlightApi.updateMoodLight({
          moodLightUpdateDto: [
            {
              ...moodLight,
              category: moodLight.category,
              modified: new Date().toISOString(),
              userId: user.id,
            },
          ],
        });

        const response: CustomMoodLight = {
          ...data[0],
          category: data[0].category,
          modified: new Date(data[0].modified).getTime(),
        };

        dispatch(upsertAccountMoodLight(moodLight));

        return response;
      },
      {onSuccess, onError},
    ),
    [user, onSuccess, onError],
  );
}

export function useUseMoodLight() {
  return useAsyncFn(async (moodLightId: string) => {
    Analytics.shared().logCustomEventAction(
      CustomEventAction.USE_MOODLIGHT,
      CustomCategory.MOODLIGHT,
      CustomLabel.MOODLIGHT_ID,
      {moodLightId},
    );

    await moodlightApi.useMoodLight({moodLightId});
  }, []);
}

type DeletedInfo = {
  deletedMoodLight: CustomMoodLight;
  matchingActives: ProfileT[];
  matchingArchives: ProfileT[];
  shouldTurnOffLantern: boolean;
  matchingTemp?: ProfileT;
};

export function useDeleteMoodLight() {
  const deletedInfo = React.useRef<DeletedInfo | null>(null);
  const dispatch = useAppDispatch();
  const peak = useSelector(connectedPeakSelector);
  const currentDeviceId = useSelector(currentDeviceIdSelector);
  const lanternMoodLight = useSelector(lanternMoodLightSelector);
  const isLanternModeOn =
    useSelector(currentDeviceSettingsSelector)?.lanternMode ?? false;
  const archives = useSelector(archiveProfilesSelector);
  const actives = useSelector(activeProfilesSelector);
  const tempProfile = useSelector(tempProfileSelector);
  const user = useSelector(userSelector);
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();

  const whitenActivesTempLantern = React.useCallback(
    ({
      matchingActives,
      matchingTemp,
      deletedMoodLight,
      shouldTurnOffLantern,
    }: {
      matchingActives: DeletedInfo['matchingActives'];
      matchingTemp: DeletedInfo['matchingTemp'];
      deletedMoodLight: CustomMoodLight;
      shouldTurnOffLantern: boolean;
    }) => {
      // Whiten temp profile in Redux
      const whiteTempProfile: ProfileT | undefined = matchingTemp
        ? {
            ...setProfileToWhite(matchingTemp),
            order: Constants.TEMP_HEAT_PROFILE_INDEX,
          }
        : undefined;
      if (whiteTempProfile) {
        dispatch(updateTempProfile(whiteTempProfile));
      }

      // Whiten active profiles in Redux
      matchingActives = matchingActives.map(active => {
        const whitenedProfile = setProfileToWhite(active);
        dispatch(updateActiveProfile(whitenedProfile));
        return whitenedProfile;
      });

      // Whiten active and temp profiles on the device
      const combinedProfiles = [
        ...matchingActives,
        ...(whiteTempProfile ? [whiteTempProfile] : []),
      ];
      combinedProfiles.length > 0 &&
        peak?.writeHeatProfiles(
          combinedProfiles,
          combinedProfiles.map(() => undefined),
        );

      const attachedActivesDictionary: Dictionary<string, Profile> = {};
      matchingActives.forEach(
        active => (attachedActivesDictionary[active.id] = active),
      );

      // Whiten lantern
      const shouldWhitenLantern =
        !!lanternMoodLight && lanternMoodLight.id === deletedMoodLight.id;

      if (shouldWhitenLantern) {
        dispatch(setLanternMoodLightId());

        if (isLanternModeOn) {
          if (shouldTurnOffLantern) peak?.stopLantern();
          peak?.clearScratchpad();
          dispatch(
            updateDeviceSettings({
              syncUserLanternPreference: true,
              id: currentDeviceId ?? '',
              settings: {
                lanternMode: false,
                lanternColor: appColors.defaultColor,
              },
            }),
          );
        }
      }

      // Update Peak mood lights in Redux
      dispatch(
        setPeakMoodLights(
          getUpdatedPeakMoodLights({
            actives: actives.map(
              active => attachedActivesDictionary[active.id] ?? active,
            ),
            lanternMoodLight: undefined,
          }),
        ),
      );
    },
    [
      peak,
      actives,
      currentDeviceId,
      getUpdatedPeakMoodLights,
      isLanternModeOn,
      lanternMoodLight,
    ],
  );

  const whitenMatchingArchives = React.useCallback(
    (matchingArchives: DeletedInfo['matchingArchives']) => {
      // Whiten archive profiles in Redux
      user &&
        dispatch(
          updateArchiveProfiles(
            matchingArchives.map(archive => ({
              ...setProfileToWhite(archive),
              userId: user.id,
            })),
          ),
        );
    },
    [user],
  );

  const deleteActiveMoodLight = React.useCallback(
    (deletedInfo: DeletedInfo) => {
      const {
        deletedMoodLight,
        matchingActives,
        matchingTemp,
        shouldTurnOffLantern,
      } = deletedInfo;
      whitenActivesTempLantern({
        matchingActives,
        matchingTemp,
        deletedMoodLight,
        shouldTurnOffLantern,
      });
    },
    [whitenActivesTempLantern],
  );

  const [state, action] = useAsyncFn(
    async (moodLightId: string) => {
      try {
        moodlightApi.deleteMoodLight({moodLightId});

        if (!deletedInfo.current) return;

        dispatch(deleteAccountMoodLight(deletedInfo.current.deletedMoodLight));
        whitenMatchingArchives(deletedInfo.current.matchingArchives);
        deleteActiveMoodLight(deletedInfo.current);

        deletedInfo.current = null;
      } catch (error) {
        Alert.alert(Alerts.MOOD_DELETE_ERROR_TITLE, (error as Error).message);
        deletedInfo.current = null;

        throw error;
      }
    },
    [deleteActiveMoodLight, whitenMatchingArchives],
  );

  const deleteMoodLight = React.useCallback(
    ({
      moodLight,
      isPeakMoodLight,
      shouldTurnOffLantern,
    }: {
      moodLight: CustomMoodLight;
      isPeakMoodLight: boolean;
      shouldTurnOffLantern: boolean;
    }) => {
      const profileMatchesMoodLight = (profile: Profile) =>
        isTHeatProfileMoodLight(profile) &&
        profile.moodLightId === moodLight.id;
      const matchingActives = actives.filter(
        profileMatchesMoodLight,
      ) as ProfileT[];
      const matchingArchives = archives.filter(
        profileMatchesMoodLight,
      ) as ProfileT[];

      Alert.alert(
        Alerts.CONFIRM_DELETION,
        matchingArchives.length > 0 || matchingActives.length > 0
          ? interpolate(Alerts.CONFIRM_WHITEN_PROFILES, {
              allNames: (() => {
                const profileMap = new Map<string, Profile>();
                [...matchingActives, ...matchingArchives].forEach(
                  profile =>
                    !profileMap.has(profile.id) &&
                    profileMap.set(profile.id, profile),
                );
                return [...profileMap.values()]
                  .map(profile => profile.name)
                  .join(', ');
              })(),
            })
          : interpolate(Alerts.CONFIRM_DELETE_MOOD_LIGHT, {
              moodLight: moodLight?.name ?? 'this mood light',
            }),
        [
          {text: 'Cancel'},
          {
            text: 'OK',
            onPress: async () => {
              const deleteInformation = {
                deletedMoodLight: moodLight,
                matchingActives,
                matchingArchives,
                shouldTurnOffLantern,
                ...(tempProfile &&
                  isTHeatProfileMoodLight(tempProfile) &&
                  tempProfile.moodLightId === moodLight.id && {
                    matchingTemp: tempProfile,
                  }),
              };
              if (isPeakMoodLight) {
                deleteActiveMoodLight(deleteInformation);
              }
              deletedInfo.current = deleteInformation;
              await action(moodLight.id);
            },
          },
        ],
      );
    },
    [
      actives,
      action,
      archives,
      deleteActiveMoodLight,
      tempProfile,
      deletedInfo,
    ],
  );

  return [state, deleteMoodLight] as const;
}

export function useGetAndStoreExclusiveMoodLights(doesAutoGet = true) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [state, get] = useAsyncFn(async () => {
    const {data} = await moodlightApi.getActiveExclusiveMoodLights();

    dispatch(
      setExclusiveMoodLights(
        data.map<ExclusiveMoodLight>(
          ({
            cardMedia,
            fullscreenMedia,
            tableColor: tableColorString,
            colorArray,
            offsetArray,
            animationArray,
            ...rest
          }) => {
            const tableColorArr = convertHexStringToNumArray(tableColorString);

            return {
              ...rest,
              fullscreenMedia: {},
              category: MoodLightCategory.Exclusive,
              cardImageUrl: cardMedia.originalUrl,
              fullScreenImageUrl: fullscreenMedia.originalUrl,
              rawMoodLight: {
                tableColor: {
                  brightness:
                    tableColorArr[Constants.TABLE_COLOR_BYTES.BRIGHTNESS],
                  speed: tableColorArr[Constants.TABLE_COLOR_BYTES.SPEED],
                  lumaAnimation:
                    tableColorArr[Constants.TABLE_COLOR_BYTES.LUMA_ANIMATION],
                  phaseLockNumerator:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.PHASE_LOCK_NUMERATOR
                    ],
                  phaseLockDenominator:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.PHASE_LOCK_DENOMINATOR
                    ],
                  arrayIndices:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.COLOR_AND_OFFSET_ARRAY_INDICES
                    ],
                  colorArrayLength:
                    tableColorArr[
                      Constants.TABLE_COLOR_BYTES.COLOR_ARRAY_LENGTH
                    ],
                },
                colorArray: convertHexArrayToNumArray(colorArray),
                offsetArray,
                ...(animationArray && {
                  animationArray: convertAnimArrayToNumArray(animationArray),
                }),
              },
            };
          },
        ),
      ),
    );

    return data;
  }, [user]);

  React.useEffect(() => {
    if (!doesAutoGet) return;
    get();
  }, [doesAutoGet, get]); // Pull fresh exclusives on login

  return [state, get] as const;
}

export function useGetAndUpdateUsernameCache() {
  const dispatch = useDispatch();
  const loggedIn = !!useSelector(userSelector);
  const usernameCache = useSelector(usernameCacheSelector);

  const peakMoodLights = useSelector(peakMoodLightsSelector);
  const accountMoodLights = useSelector(accountMoodLightsSelector);

  React.useEffect(() => {
    if (!loggedIn) return;

    const ids = [
      ...peakMoodLights
        .map(moodLight =>
          isCustomMoodLight(moodLight) ? moodLight.originalMoodLightId : null,
        )
        .filter((id): id is string => !!id), // we only want custom mood lights with originalMoodLightIds
      ...accountMoodLights.map(moodLight => moodLight.originalMoodLightId),
    ];

    if (!ids.length) return;

    moodlightApi
      .getMoodLightCreatorUsernamesBulk({requestBody: ids})
      .then(({data}) => dispatch(updateUsernameCache(data)));
  }, [accountMoodLights, peakMoodLights, loggedIn]);

  return usernameCache;
}
