import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAsync} from 'react-use';

import {backgroundPasswordConfirmation} from '../../assets/images';
import {useSpinner} from '../../components';
import {Screens} from '../../constants';
import {isHttpError, publicUserApi, userApi} from '../../lib/api/apis';
import {openApp} from '../../lib/hooks/useOpenApp';
import {currentDeviceIdSelector} from '../../lib/redux/bleSlice';
import {setUser, userSelector} from '../../lib/redux/userSlice';
import {RootStackScreenProps} from '../../navigation/navigators/RootStackNavigator';
import {
  toBluetoothStartPairingRedirect,
  toHome,
} from '../../navigation/navigators/util';
import {DesktopConfirmationScreen} from './DesktopConfirmationScreen';

const getMessagesFromResponse = (value?: boolean, error?: Error) => {
  if (value)
    return {
      title: 'Email Confirmed!',
      body: 'You now have full access to the Puffco app and all of the future feature releases.',
      button: 'Start Exploring',
    };

  if (!error) return {title: 'Processing', body: 'Verifying email.'};

  if (isHttpError(error) && error.response?.status === 403)
    return {
      title: 'Verification Link Expired',
      body: 'This verification link has expired. Please resend verification in the account screen.',
    };

  return {
    title: 'Email Not Confirmed!',
    body: 'Something went wrong. Please try the link again or resend verification in the account screen.',
    button: 'Got it',
  };
};

export interface Props {
  token: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.EmailConfirmed> {}

export const EmailConfirmedScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const hasDevice = !!useSelector(currentDeviceIdSelector);

  const accessToken = route?.params?.token;

  const {error, loading, value} = useAsync(async () => {
    await publicUserApi.verify(
      {id: 'me'},
      {headers: {Authorization: `Bearer ${accessToken}`}},
    );

    if (!user?.id) return true;

    const updatedUser = await userApi
      .getUserById({id: 'me'})
      .then(({data}) => data);

    dispatch(setUser(updatedUser));

    return true;
  }, [accessToken, user?.id]);

  useSpinner({isVisible: loading});

  const {title, body, button} = getMessagesFromResponse(value, error);

  return (
    <DesktopConfirmationScreen
      background={backgroundPasswordConfirmation}
      {...{title, body}}
      buttonTitle={button}
      onPress={() =>
        openApp('', () =>
          navigation.navigate(
            ...(hasDevice ? toHome : toBluetoothStartPairingRedirect.value),
          ),
        )
      }
    />
  );
};
