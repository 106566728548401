import {AuditLog1Type, LogIndex, LogRecord, MoodlightType} from 'pikaparam';
import {
  DabHistoryDataDto,
  DabHistoryMoodLightDataDto,
  DeviceLogType,
  DeviceLogsDto,
  TemperatureUnit,
} from 'puffco-api-axios-client';
import React from 'react';
import {useSelector} from 'react-redux';
import {useAsync, useThrottleFn} from 'react-use';

import {Constants} from '../../constants';
import {appLog, deviceLog} from '../Logger';
import {peakDeviceApi, userApi} from '../api/apis';
import {
  addDeviceLogs,
  connectedPeakSelector,
  currentDeviceLogsSelector,
  currentDeviceSelector,
  removeDeviceLogs,
} from '../redux/bleSlice';
import {userSelector} from '../redux/userSlice';
import {isDefined} from '../util/types';
import {Temperature} from '../utils/temperature';
import {useAppDispatch} from './useAppDispatch';

interface ParsedLogDetail {
  name?: string;
  value?: string;
}

abstract class LogParser<T> {
  private details: ParsedLogDetail[];

  constructor(protected log: DeviceLogsDto) {
    this.details = JSON.parse(log.details);
  }

  protected getValue(name: string) {
    return this.details.find(d => d.name === name)?.value;
  }

  abstract toLog(): T;
}

class DabLogParser extends LogParser<DabHistoryDataDto | undefined> {
  private static types = [
    AuditLog1Type.ActiveHeatAborted,
    AuditLog1Type.HeatCycleCompleted,
  ];

  private static min =
    Temperature.convert(Constants.TEMPERATURE_MIN_FAHRENHEIT, {
      from: TemperatureUnit.Fahrenheit,
      to: TemperatureUnit.Celsius,
    }) * 0.9; // 10% threshold

  static create(log: DeviceLogsDto) {
    if (!log.code || !this.types.includes(log.code)) return;

    return new DabLogParser(log);
  }

  toLog(): DabHistoryDataDto | undefined {
    const temperature = this.getTemperature();

    if (temperature <= DabLogParser.min) return;

    return {
      dabAt: this.log.timestamp,
      temperature,
      duration: this.getDuration(),
    };
  }

  private getTemperature() {
    try {
      const temperature = this.getValue('Nominal temp');

      if (!temperature) return 0;

      // '254.5 degC' => 254.5
      return parseFloat(temperature.slice(0, temperature.length - 5));
    } catch {
      return 0;
    }
  }

  private getDuration() {
    try {
      const duration = this.getValue('Elapsed time');

      if (!duration) return 0;

      // '55.23 sec' => 55.23
      return parseFloat(duration.slice(0, duration.length - 4));
    } catch {
      return 0;
    }
  }
}

class MoodlightEndedLogParser extends LogParser<
  DabHistoryMoodLightDataDto | undefined
> {
  static create(log: DeviceLogsDto) {
    if (log.code !== AuditLog1Type.MoodLightEnded) return;

    return new MoodlightEndedLogParser(log);
  }

  toLog(): DabHistoryMoodLightDataDto | undefined {
    if (!this.isHeatCycle()) return;

    const moodLightIdHex = this.getValue('ULID8')
      ?.toLowerCase()
      ?.replace(/\s/g, '');

    if (!moodLightIdHex) return;

    return {
      dabEnd: this.log.timestamp,
      moodLightIdHex,
    };
  }

  private isHeatCycle() {
    return this.getValue('Type') === MoodlightType.HeatCycle;
  }
}

export const useLogSync = () => {
  const device = useSelector(currentDeviceSelector);
  const peak = useSelector(connectedPeakSelector);
  const logs = useSelector(currentDeviceLogsSelector);
  const user = useSelector(userSelector);

  const dispatch = useAppDispatch();

  const serialNumber = device?.serialNumberString;
  const deviceId = device?.id;
  const canRead = !!peak?.attributes;
  const userId = user?.id;

  // Load last synced offsets
  const {value} = useAsync(async () => {
    if (!serialNumber) throw new Error('Skipped because missing serial.');

    if (!canRead) throw new Error('Skipped because no peak is connected.');

    return peakDeviceApi.getMaxOffset({serialNumber}).then(r => r.data);
  }, [serialNumber, canRead]);

  // Sync logs from the peak to redux
  React.useEffect(() => {
    if (!deviceId || !serialNumber || !canRead) return;
    if (!value) return;

    const onRecords = (records: LogRecord[]) => {
      records.forEach(
        ({logIndex, recordIndex, typeCode, title, correctedDate, freeze}) => {
          deviceLog.info('Device log received.', {
            serialNumber,
            logIndex,
            recordIndex,
            typeCode,
            title,
            date: correctedDate?.toISOString(),
            freeze,
          });
        },
      );

      dispatch(
        addDeviceLogs({
          id: deviceId,
          logs: records.map<DeviceLogsDto>(record => ({
            type:
              record.logIndex === LogIndex.Audit
                ? DeviceLogType.Audit
                : DeviceLogType.Fault,
            code: record.typeCode,
            offset: record.recordIndex,
            state: record.title,
            timestamp: (record.correctedDate ?? new Date()).toISOString(),
            details: JSON.stringify(record.freeze),
          })),
        }),
      );
    };

    const configs = [DeviceLogType.Audit, DeviceLogType.Fault].map(type => {
      const max = value[type];
      const begin = max ? max + 1 : undefined;
      return {type, begin};
    });

    appLog.info('Subscribed to device logs.', {configs});

    peak.subscribeToLogs({configs, onRecords});
  }, [value, peak, deviceId, serialNumber, canRead]);

  // Sync logs from redux to API
  useThrottleFn(
    (serialNumber, deviceId, logs, userId) => {
      if (!deviceId || !serialNumber) return;
      if (!logs?.length) return;

      const syncDabs = async (userId?: number) => {
        if (!userId) return;

        const data = logs
          .map(
            log =>
              DabLogParser.create(log) ?? MoodlightEndedLogParser.create(log),
          )
          .map(item => item?.toLog())
          .filter(isDefined);

        if (!data.length) return;

        await userApi
          .sync({
            id: 'me',
            serialNumber,
            dabHistoryCreateDto: {data},
          })
          .catch(error => appLog.error("Couldn't sync dab history.", {error}));
      };

      syncDabs(userId).then(async () => {
        await peakDeviceApi
          .updateDeviceLogs({
            serialNumber,
            peakDeviceUpdateDto: {serialNumber, deviceLogs: logs},
          })
          .then(() => dispatch(removeDeviceLogs({id: deviceId, logs})))
          .catch(error => appLog.error("Couldn't sync device logs.", {error}));
      });
    },
    5000,
    [serialNumber, deviceId, logs, userId] as const,
  );
};
