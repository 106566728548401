import {AgreementType, UserType} from 'puffco-api-axios-client';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useUpdateEffect} from 'react-use';
import {ulid} from 'ulid';

import {Analytics} from '../../analytics/Analytics';
import {agreementTrackingApi, userApi} from '../api/apis';
import {
  appSettingsSelector,
  updateAppSettings,
} from '../redux/appSettingsSlice';
import {userSelector} from '../redux/userSlice';

export const useAgreement = () => {
  const {agreeAgreement} = useSelector(appSettingsSelector);
  const userId = useSelector(userSelector)?.id;
  const dispatch = useDispatch();

  const generatedUserId = React.useMemo(
    () => agreeAgreement.generatedUserId ?? ulid(),
    [agreeAgreement.generatedUserId],
  );

  React.useEffect(() => {
    if (agreeAgreement.generatedUserId) return;

    dispatch(
      updateAppSettings({agreeAgreement: {...agreeAgreement, generatedUserId}}),
    );
  }, [generatedUserId, agreeAgreement]);

  const linkUser = React.useCallback(async () => {
    const data = await userApi
      .checkUserPair({id: 'me', generatedUserId})
      .then(r => r.data)
      .catch(() => void 0);

    if (data?.exist && !data.otherUser) {
      await agreementTrackingApi.updateAgreementTracking({generatedUserId});
    }

    return data;
  }, [generatedUserId]);

  const getStatus = React.useCallback(async () => {
    const get = async () => {
      if (agreeAgreement[AgreementType.Tc] && agreeAgreement[AgreementType.Pp])
        return agreeAgreement;

      const agreements = await agreementTrackingApi
        .checkUserLatestAgreementStatus(
          userId
            ? {userId: userId.toString(), userType: UserType.Existing}
            : {userId: generatedUserId, userType: UserType.Anonymous},
        )
        .then(r => r.data)
        .catch(() => []);

      const agreement = {
        generatedUserId,
        [AgreementType.Tc]: agreements.some(a => a.type === AgreementType.Tc),
        [AgreementType.Pp]: agreements.some(a => a.type === AgreementType.Pp),
      };

      updateAppSettings({agreeAgreement: agreement});

      return agreeAgreement;
    };

    const status = await get();

    Analytics.shared().setAgreeConsent(
      !!status[AgreementType.Tc] && !!status[AgreementType.Pp],
    );

    return status;
  }, [userId, generatedUserId, agreeAgreement]);

  useUpdateEffect(() => {
    if (!userId) return;

    linkUser().catch(() => void 0);
  }, [userId, linkUser]);

  return {getStatus};
};
