import React from 'react';
import {AppState, AppStateStatus} from 'react-native';

export const useAppState = () => {
  const [state, setState] = React.useState<AppStateStatus>(
    AppState.currentState,
  );

  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', setState);

    return () => subscription.remove();
  }, []);

  return state;
};
